@use '../../styles' as *;

.billing {
  gap: 2rem;
  @include standartBlock($width: 50%, $justify: center);
  @media screen and (max-width: 1200px) {
    width: 60%;
  }
  @media screen and (max-width: 1024px) {
    width: 70%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  &__topBlock {
    @include standartBlock($align: flex-start);
    gap: 1rem;

    &__title {
      font-size: 1.72rem;
      font-weight: 500;
      color: $boldHeaderFont;
    }

    p {
      font-size: 1.29rem;
      font-weight: 500;
      color: $boldHeaderFont;
    }
  }
}
