@use '../../../styles' as *;

.area {
  @include standartBlock($width: 100%, $justify: flex-start);
  padding: 1.5rem;
  border: 1px solid $cellBorder;
  border-radius: 24px;
  overflow: hidden;

  &__topBlock {
    @include standartBlock($direction: row);
    padding: 1rem;

    &__title {
      font-size: 1.7rem;
      font-weight: 500;
      color: $boldHeaderFont;
    }

    &__successMessage {
      color: green;
    }

    &__errorMessage {
      color: red;
    }

    button {
      position: relative;
      display: flex;
      width: auto;
      padding: 0;
      margin: 0;
      border: 0;
      background: none;
    }

    &__svg {
      width: 1.7rem;
      height: 1.7rem;
    }
  }

  &__bottomBlock {
    @include standartBlock();
    border-radius: 1.15rem;
    overflow: hidden;

    &__hiddenScreen {
      @include standartBlock();

      &__btns {
        @include standartBlock($direction: row);
        position: absolute;
        padding: 2rem;
        z-index: 10;

        &__group {
          position: relative;
          display: flex;
          gap: 1rem;
        }

        button {
          @include standartBlock($width: auto);
          padding: 0.7rem;
          border-radius: 50%;
          border: none;
          background: rgba($color: #000000, $alpha: 0.4);

          .svg {
            width: 2rem;
            height: 2rem;
          }
        }
      }

      img {
        width: 100%;
        object-fit: cover;
      }
    }

    &__images {
      display: grid;
      width: 100%;
      grid-template-columns: repeat(2, minmax(50px, 1fr));
      grid-auto-flow: dense;
      gap: 2rem;

      &__imgBlock {
        position: relative;
        display: flex;
        cursor: pointer;

        img {
          width: 100%;
          object-fit: cover;
          z-index: 10;
        }

        &__loaderBlock {
          @include standartBlock($justify: center);
          position: absolute;
          height: 100%;
          z-index: 15;
        }
      }
    }
  }
}
