@use '../../styles' as *;

.modal {
  @include standartBlock($justify: center);
  background: $headerBackground;
  flex: 1 1 auto;

  &__form {
    @include standartBlock($width: 32%, $align: left);
    padding: 3rem 2.3rem;
    background: $modalBackground;
    border-radius: 24px;
    gap: 2rem;

    &__title {
      font-size: 2.3rem;
      font-weight: 500;
      color: $boldHeaderFont;
    }

    &__group {
      @include standartBlock($align: flex-start);
      gap: 1rem;

      &__title {
        font-size: 1.2rem;
        font-weight: 400;
        color: $boldHeaderFont;
      }

      &__workspaceBlock {
        @include standartBlock();
        gap: 1rem;

        &__label {
          @include standartBlock($direction: row, $justify: flex-start);
          padding: 0.8rem;
          padding-left: 0%;
          font-size: 1.15rem;
          font-weight: 400;
          border: 1px solid rgba(40, 40, 40, 0.25);
          border-radius: 5px;
          cursor: pointer;
          gap: 1rem;

          input {
            appearance: none;
          }

          &__checkmark {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.2rem;
            line-height: 1;
            top: 50%;
            left: 95%;
            transform: translate(-50%, -50%);
          }
        }

        &__labelActive {
          border: 1px solid var(--primary-color);
          background: $checkedBackground;
          font-weight: 500;
          color: var(--primary-color);
        }
      }

      &__select {
        @include standartBlock($align: flex-start);
        padding: 1.15rem 2.8rem;
        font-size: 1.1.5rem;
        font-weight: 400;
        border: 1px solid rgba(40, 40, 40, 0.25);
        border-radius: 5px;
        background: none;
        cursor: pointer;
        gap: 1rem;
        appearance: none;

        &:focus-visible {
          outline: 1px solid var(--primary-color);
          border: 1px solid white;
        }

        option {
          height: 2rem;
        }
      }
    }

    &__btnBlock {
      @include standartBlock($align: flex-end);

      button {
        width: 27%;
      }
    }
  }
}
.modal__form__btnBlock {
  margin-top: 1rem;
}
.modal__form__group {
  margin-top: 0.8rem;
}
