@use '../../../styles' as *;

.btn {
  color: rgb(82, 82, 91);
  border-radius: 0.5rem !important;
  min-height: 40px;
  &__submit {
    @extend .btn;
    background-color: var(--primary-color);
    color: #fff;
  }
  &__active {
    @extend .btn;
    background-color: #fff;
    color: rgb(39, 39, 42);
    box-shadow: rgba(9, 9, 11, 0.1) 0px 1px 2px -1px, rgba(9, 9, 11, 0.1) 0px 1px 3px 0px;
  }
  &__dropdown {
    @extend .btn__active;
    &::after {
      display: none;
    }
  }
}

.textarea {
  width: 100%;
  border: none !important;
  outline: none !important;
  height: auto;
  resize: none;
  min-height: 100%;
  font-size: 1.15rem;
}

.form__label__title {
  font-size: 1.15rem;
  font-weight: 400;
  color: $boldHeaderFont;
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.imageDescToggle {
  min-height: 44px;
  outline: none !important;
  box-shadow: none !important;
  font-size: 1.25rem;
}

.close_button:hover {
  // border: 1px solid $activeNav;
  // padding: 1px;
  // transition: 0.3s;
  // border-radius: 0%;
}
