@use '../../../styles' as *;

.generated {
  position: relative;
  display: flex;
  cursor: pointer;
  background: $formBorder;
  min-height: 10rem;

  img {
    width: 100%;
    object-fit: cover;
    z-index: 10;
  }

  &__imgLoaderBlock {
    @include standartBlock($justify: center);
    position: absolute;
    height: 100%;
    z-index: 15;
  }
}
