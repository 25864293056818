@use '../../../styles/' as *;

.maping {
  @include standartBlock($align: flex-start);
  padding: 2rem 2rem;
  background: white;
  border-radius: 0.5rem;
  width: 30%;
  z-index: 10;
  gap: 2rem;

  &__title {
    font-size: 1.3rem;
    font-weight: 500;
    color: $boldHeaderFont;
  }

  background-color: white;

  &__modalSubmitOuter {
    display: flex;
    justify-content: flex-end;
  }

  &__modalCloseButton {
    width: auto;
    height: 37px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 4px;
    font-weight: 500;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    padding: 0.8rem 1rem;
    font-size: 1.15rem;
    text-transform: capitalize;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  &__modalSubmitButton {
    width: auto;
    height: 37px;
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    border: 1px solid;
    border-radius: 4px;
    font-weight: 500;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    padding: 0.8rem 1rem;
    font-size: 1.15rem;
    text-transform: capitalize;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  &__form {
    @include standartBlock($align: space-between);
    gap: 1.5rem;

    &__row {
      @include standartBlock($direction: row);
      background: none;

      &__title {
        font-size: 1.29rem;
        font-weight: 500;
        color: $boldHeaderFont;
      }

      select {
        width: 40%;
        padding: 0.5rem;
        border-radius: 0.4rem;
        border: 1px solid $formBorder;
        background: none;

        &:focus-visible {
          border: 1px solid var(--primary-color);
          outline: none;
        }
      }
    }
  }
}
