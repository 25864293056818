@use '../../styles' as *;

.apikey {
  @include standartBlock($width: 50%, $justify: center);
  gap: 2rem;
  @media screen and (max-width: 1200px) {
    width: 75%;
  }
  @media screen and (max-width: 1024px) {
    width: 90%;
  }
  @media screen and (max-width: 768px) {
    width: 95%;
  }

  &__container {
    @include standartBlock($align: flex-start);
    padding: 1.72rem;
    border: 1px solid $formBorder;
    border-radius: 24px;
    gap: 2.5rem;

    &__topBlock {
      @include standartBlock($align: flex-start);
      gap: 1rem;

      &__title {
        font-size: 1.72rem;
        font-weight: 500;
        color: $boldHeaderFont;
        text-transform: capitalize;
      }

      p {
        font-size: 1.29rem;
        font-weight: 400;
        color: $boldHeaderFont;
      }
    }

    &__centralBlock {
      @include standartBlock();
      gap: 2rem;

      &__inputsBlock {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(2, minmax(50px, 1fr));
        grid-auto-flow: dense;
        gap: 2rem;

        &__label {
          @include standartBlock($align: flex-start, $justify: flex-start);
          gap: 1rem;

          &__title {
            font-size: 1.15rem;
            font-weight: 400;
            color: $boldHeaderFont;
            text-transform: capitalize;
          }

          input {
            @include standartBlock();
            padding: 1rem;
            font-size: 1.15rem;
            font-weight: 400;
            font-family: 'Roboto';
            color: $boldHeaderFont;
            border-radius: 4px;
            border: 1px solid $formBorder;

            &:focus-visible {
              border: 1px solid $modalBackground;
              outline: 1px solid var(--primary-color);
            }
          }
        }
      }
    }

    &__btnBlock {
      @include standartBlock($direction: row, $justify: flex-end);
      gap: 2rem;
      font-size: 1rem;

      &__successMessage {
        color: green;
      }

      &__errorMessage {
        color: red;
      }

      button {
        width: 20%;
        text-transform: capitalize;
      }
    }
  }
}

.info {
  @include standartBlock($width: 100%, $direction: 'row', $justify: flex-start, $align: center);
  gap: 1.25rem;
  flex: 1;

  input {
    @include standartBlock();
    padding: 0.8rem;
    font-size: 1.15rem;
    font-weight: 400;
    width: 100%;
    font-family: 'Roboto';
    color: $boldHeaderFont;
    border-radius: 4px;
    border: 1px solid $formBorder;

    &:focus-visible {
      border: 1px solid $modalBackground;
      outline: 1px solid var(--primary-color);
    }
  }

  &__btnBlock {
    @include standartBlock($width: auto, $direction: row, $justify: flex-start, $align: flex-start);
    gap: 1.25rem;
  }

  button {
    text-transform: capitalize;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  &__icon {
    animation: rotate 0.5s linear infinite reverse;
  }
  &__svg {
    width: 1.7rem;
    height: 1.7rem;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
