@use '../../../styles/' as *;

.thead {
  background: $tableHeaderBackground;

  th {
    // width: fit-content !important;
    font-size: 1.15rem;
    font-weight: 500;
    color: $headerFont;
    background-color: #f3f3f3;
    text-align: center;
    text-transform: capitalize;
    padding: 1rem 0.5rem;
    border: 1px solid rgba(40, 40, 40, 0.5);
  }

  &__buttonHeader {
    width: 3rem;
  }

  &__questionHeader {
    width: 15rem;
  }

  &__selectHeader {
    width: 8rem;
  }

  &__checkboxHeader {
    width: 5rem;
  }

  &__imagesHeader {
    width: 6rem;
  }
}

.required {
  color: red;
}
