@use '../../../../styles' as *;

.referral {
  @include standartBlock($width: 80%, $align: flex-start);

  gap: 2rem;
  @media screen and (max-width: 1200px) {
    width: 85%;
  }
  @media screen and (max-width: 1024px) {
    width: 90%;
  }
  @media screen and (max-width: 768px) {
    width: 95%;
  }
  &__container {
    @include standartBlock($width: 100%, $align: flex-start);
    padding: 1.72rem;
    border: 1px solid $formBorder;
    border-radius: 24px;
    background: $boldHeaderFont;
    // gap: 2.5rem;
    // position: relative;
    top: 0;

    &__topBlock {
      @include standartBlock($align: flex-start);
      gap: 1rem;

      &__title {
        font-size: 1.72rem;
        font-weight: 500;
        color: $modalBackground;
        text-transform: capitalize;
      }

      p {
        font-size: 1.29rem;
        font-weight: 400;
        color: $boldHeaderFont;
      }
    }

    &__centralBlock {
      @include standartBlock();
      gap: 2rem;

      &__inputsBlock {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(2, minmax(50px, 1fr));
        grid-auto-flow: dense;
        gap: 2rem;

        &__label {
          @include standartBlock($align: flex-start, $justify: flex-start);
          gap: 1rem;

          &__title {
            font-size: 1.15rem;
            font-weight: 400;
            color: $boldHeaderFont;
            text-transform: capitalize;
          }

          input {
            @include standartBlock();
            padding: 1rem;
            font-size: 1.15rem;
            font-weight: 400;
            font-family: 'Roboto';
            color: $boldHeaderFont;
            border-radius: 4px;
            border: 1px solid $formBorder;

            &:focus-visible {
              border: 1px solid $modalBackground;
              outline: 1px solid var(--primary-color);
            }
          }
        }
      }
    }

    &__btnBlock {
      @include standartBlock($direction: row, $justify: flex-end);
      gap: 2rem;
      font-size: 1rem;

      &__successMessage {
        color: green;
      }

      &__errorMessage {
        color: red;
      }

      button {
        width: 20%;
        text-transform: capitalize;
      }
    }
  }

  &__stats {
    @include standartBlock($width: 20%, $align: flex-start);
    padding: 1.45rem 1.45rem 0 1.45rem;
    flex-wrap: wrap;
    border-radius: 24px;
    border: 1px solid $formBorder;
    background: $progressBar;
    overflow: hidden;
    &__container {
      width: 16rem;
      position: relative;
      &__prop {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-48%);
        font-size: 1.8rem;
        font-weight: 600;
        color: $boldHeaderFont;
      }
    }
  }
}

.white_label {
  &__title {
    font-size: 1.72rem;
    font-weight: 500;
    text-transform: capitalize;
    color: $boldHeaderFont;
  }
  &__sub_title {
    font-size: 1.29rem;
    font-weight: 400;
    color: $boldHeaderFont;
  }
}

.info {
  @include standartBlock($width: 100%, $direction: 'row', $justify: flex-start, $align: center);
  gap: 1.25rem;
  flex: 1;
  input {
    padding: 0.8rem;
    font-size: 1.15rem;
    font-weight: 400;
    width: 100%;
    font-family: 'Roboto';
    background: $modalBackground;
    border: none;
    &:focus-visible {
      border: 1px solid $modalBackground;
      outline: 1px solid var(--primary-color);
    }
  }

  &__btnBlock {
    @include standartBlock($width: auto, $direction: row, $justify: flex-start, $align: flex-start);
    gap: 1.25rem;
  }

  button {
    text-transform: capitalize;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  &__icon {
    animation: rotate 0.5s linear infinite reverse;
  }
  &__svg {
    width: 1.7rem;
    height: 1.7rem;
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.quickAccess {
  @include standartBlock($align: flex-start);
  gap: 1.5rem;

  &__title {
    font-size: 1.8rem;
    font-weight: 500;
    color: $boldHeaderFont;
  }

  &__row {
    &__item {
      @include standartBlock($width: 100%, $align: flex-start, $justify: flex-start);
      height: 120px;
      border: 1px solid rgba(40, 40, 40, 0.15);
      border-radius: 24px;
      padding: 1.5rem;
      display: flex;
      justify-content: center;
      &__icon_box {
        width: 100%;
        // height: 5rem;
        padding: 1rem;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &__title {
        font-size: 1.3rem;
        font-weight: 500;
        color: $boldHeaderFont;
      }

      &__description {
        font-size: 1.18rem;
        font-weight: 400;
        color: $headerFont;
      }

      &__bottomBlock {
        @include standartBlock($direction: row, $justify: flex-end);

        &__btn {
          @include standartBlock($width: auto, $direction: row);
          font-size: 1rem;
          font-weight: 500;
          color: $boldHeaderFont;
          padding: 0.9rem 1.7rem;
          border-radius: 2.3rem;
        }
      }
    }
  }
}

.imageContainer p {
  font-size: 1.15rem;
  font-weight: 400;
  color: var(--primary-font-color);
  text-transform: capitalize;
}

.form {
  label {
    font-size: 1.15rem;
    font-weight: 400;
    color: var(--primary-font-color);
    text-transform: capitalize;
  }

  input,
  textarea,
  select {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 1rem;
    font-size: 1.15rem;
    font-weight: 400;
    font-family: 'Roboto';
    color: var(--primary-font-color);
    border-radius: 4px;
    border: 1px solid rgba(40, 40, 40, 0.2);
  }

  input[type='checkbox'] {
    padding: unset;
  }
}
