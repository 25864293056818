@use '../../../styles' as *;

.drafts {
  @include standartBlock($align: flex-start);
  gap: 1.5rem;

  &__showDraftsBtn {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 1.29rem;
    font-weight: 500;
    color: $boldHeaderFont;
    background: none;
    border: none;
    padding: 0;
    &__svg {
      width: 1.7rem;
      height: 1.7rem;
      transform: rotate(-90deg);
    }

    &__svgActive {
      width: 1.7rem;
      height: 1.7rem;
    }
  }

  &__content {
    @include standartBlock($justify: flex-start);
    min-height: 20rem;
    padding: 1.5rem 1.5rem 3rem 1.5rem;
    border-radius: 24px;
    border: 1px solid $formBorder;
    gap: 3rem;

    &__container {
      @include standartBlock();
      gap: 2rem;

      &__panel {
        @include standartBlock($direction: row);

        &__title {
          font-size: 1.7rem;
          font-weight: 500;
          color: $boldHeaderFont;
        }

        &__successMessage {
          color: green;
        }

        &__errorMessage {
          color: red;
        }

        &__btns {
          @include standartBlock($direction: row, $width: auto);
          gap: 1rem;

          button {
            display: flex;
            align-items: center;
            justify-content: center;
            background: none;
            border: none;
          }

          &__svg {
            width: 1.7rem;
            height: 1.7rem;
          }
        }
      }

      &__generation {
        @include standartBlock($align: flex-start, $justify: flex-start);
        font-size: 1.29rem;
        font-weight: 400;
        color: $boldHeaderFont;
        word-break: normal;
        gap: 0.5rem;

        p {
          line-height: 1.5;
        }

        &__loaderBlock {
          @include standartBlock($justify: center);
          position: absolute;
          height: 100%;
        }

        &__show {
          position: relative;
          width: auto;
          border: none;
          font-size: 1.29rem;
          font-weight: 500;
          font-family: 'Roboto';
          color: var(--primary-color);
          text-transform: capitalize;
          margin: 0;
          padding: 0;
          background: none;
        }
      }
    }
  }
}

.animated {
  animation: rotation linear 1s infinite;
}
.drafts__content__container__panel__btns__svg:hover {
  border: 1px solid var(--primary-color);
  padding: 1px;
  transition: 0.3s;
  border-radius: 0%;
}

@keyframes rotation {
  100% {
    transform: rotate(360deg);
  }
}
