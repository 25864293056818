@use '../../../styles' as *;

.tabs {
  border-bottom: none;
  li {
    button {
      border: none !important;
      padding: 0;
    }
  }
  &__tabItemContent {
    font-size: 20px;
    padding: 0.25rem 1rem;
    border-radius: 4px;
    &__title {
      @extend .tabs__tabItemContent;
      color: #282828;
    }
    &__selected {
      @extend .tabs__tabItemContent;
      background-color: rgba($color: #2468ff, $alpha: 0.15);
      p {
        color: #2468ff;
      }
    }
  }
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.topBlock__title {
  font-size: 1.7rem;
  font-weight: 500;
  color: $boldHeaderFont;
}
