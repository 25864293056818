@use './colors' as *;
@use './breakpoints' as *;

@mixin standartBlock($direction: column, $justify: space-between, $align: center, $width: 100%) {
  position: relative;
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
  width: $width;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

@mixin areaTitle($size: 2.36em, $weight: 500, $color: $titleColor) {
  width: 100%;
  font-size: $size;
  font-weight: $weight;
  color: $color;
  text-align: center;
}

@mixin areaSubTitle($size: 0.92em, $weight: 400, $color: $subTitleColor) {
  width: 100%;
  font-size: $size;
  font-weight: $weight;
  color: $color;
  text-align: center;
  text-transform: uppercase;
}

@mixin bigAreaTitle() {
  font-size: 3.2rem;
  font-weight: 300;
  color: $titleColor;
}

@mixin bigAreaSubTitle() {
  font-size: 1.43rem;
  font-weight: 300;
  color: $bigSubTitleColor;
}

@mixin regularText() {
  font-size: 1.14rem;
  font-weight: 400;
  line-height: 1.73rem;
  color: $subTitleColor;
}

@mixin pageArea($direction: column, $align: center, $justify: space-between) {
  position: relative;
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
  width: 100%;
  box-sizing: border-box;
  padding: 6.2rem 8.5rem;
  margin: 0;
  background-color: $greyArea;
  gap: 3.4em;

  @media screen and (max-device-width: $tablet) {
    padding: 6rem 2rem;
  }

  @media screen and (max-device-width: $mobile) {
    padding: 5rem 2rem;
  }
}
