@use '../../../styles' as *;

.universal_notification {
  position: absolute;
  top: 27px;
  right: 31px;
  font-size: 1.12rem;
  border: 1.7px solid #d9d9d9;
  border-radius: 8px;
  color: #616161;
  padding: 1.2rem 1.8rem;
  z-index: 15;
  @media screen and (max-width: 768px) {
    top: 15px;
    width: 80%;
    margin-bottom: 10px;
  }
  &__link {
    color: var(--primary-color);
  }

  &__close {
    cursor: pointer;
    position: absolute;
    top: -1.2rem;
    right: -0.8rem;
    color: #616161;
  }
}
