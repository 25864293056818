@use '../../styles' as *;

.members {
  &__topBlock {
    @include standartBlock($direction: row);
    gap: 1rem;

    &__headers {
      @include standartBlock($width: auto, $align: flex-start);
      gap: 1rem;

      &__title {
        font-size: 1.72rem;
        font-weight: 500;
        color: $boldHeaderFont;
      }

      p {
        font-size: 1.29rem;
        font-weight: 500;
        color: $boldHeaderFont;
      }
    }

    &__btnsBlock {
      display: flex;
      gap: 1rem;

      button {
        @include standartBlock($width: auto, $direction: row);
        padding: 1rem;
        gap: 1rem;
        width: auto;
        font-size: 1.15rem;
        font-weight: 500;
        border-radius: 4px;

        &:nth-child(1) {
          border: 1px solid $boldHeaderFont;
          background: $modalBackground;
          color: $boldHeaderFont;
        }

        &:nth-child(2) {
          border: 1px solid var(--primary-color);
          background: var(--primary-color);
          color: $modalBackground;

          &:disabled {
            color: $formBorder;
            border: $cellBorder;
            background: $cellBorder;
          }
        }
      }
    }
  }

  table {
    width: 100%;
    border-radius: 23px;
    overflow: hidden;
    border: 1px solid rgba(40, 40, 40, 0.15);
    border-spacing: 0;

    thead {
      background: $tableHeaderBackground;

      th {
        font-size: 1.15rem;
        font-weight: 500;
        color: $headerFont;
        text-align: left;
        text-transform: capitalize;
        padding: 1.15rem 2.3rem;
      }
    }

    tbody {
      tr:nth-child(2n) {
        background: $tableRowBackground;
      }

      td {
        font-size: 1.15rem;
        color: $boldHeaderFont;
        font-weight: 400;
        padding: 0.9rem 2.3rem;
        opacity: 0.8;

        // &:nth-child(2) {
        //   display: flex;
        //   align-items: center;
        //   gap: 1rem;
        // }

        .members__tableImgBlock {
          position: relative;
          display: flex;
          width: 13%;

          img {
            width: 100%;
            object-fit: cover;
          }
        }
      }

      td:last-child {
        opacity: 1;
        span {
          @include standartBlock($width: auto);
          font-size: 1rem;
          font-weight: 500;
          text-transform: capitalize;
          padding: 0.5rem;
          background: $completeBackground;
          color: $completeText;
          border-radius: 8px;
        }
      }
    }
  }
}

.status_container {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 0;
  position: relative;
}

.status_active {
  background: rgba(16, 172, 132, 0.2);
  border-radius: 8px;
  color: #10ac84;
  font-size: 1rem;
  font-weight: 500;
  justify-content: space-between;
  padding: 0.5rem;
  text-transform: capitalize;
  width: auto;
}

.status_inactive {
  background: #616161;
  border-radius: 8px;
  color: white;
  font-size: 1rem;
  font-weight: 500;
  justify-content: space-between;
  padding: 0.5rem;
  text-transform: capitalize;
  width: auto;
}

.header {
  margin-bottom: 16px;
  font-size: 2.29rem;
}
.subHeader {
  font-size: 1.29rem;
  font-weight: 500;
  color: #282828;
}

.dropdownMenu {
  padding: 8px;
}
.drodownMenuButtons {
  padding: 4px;
  width: auto;
  // font-size: 1.15rem;
  // font-weight: 500;
  color: $boldHeaderFont;
  border-radius: 4px;
  margin: 4px 0px;
  display: flex;
  gap: 4px;
  align-items: center;
}
.dropdownToggle::after {
  display: none;
}
.maping {
  @include standartBlock($align: flex-start);
  padding: 2.5rem 3rem;
  background: white;
  border-radius: 0.5rem;
  width: 50%;
  z-index: 10;
  gap: 2rem;

  &__title {
    font-size: 1.8rem;
    font-weight: 500;
    color: $boldHeaderFont;
  }

  &__modalSubmitOuter {
    display: flex;
    justify-content: flex-end;
  }

  &__modalCloseButton {
    width: auto;
    height: 37px;
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    border: 1px solid;
    border-radius: 4px;
    font-weight: 500;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    padding: 0.8rem 2rem;
    font-size: 1.15rem;
    text-transform: capitalize;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  &__modalSubmitButton {
    width: auto;
    height: 37px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 4px;
    font-weight: 500;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    padding: 0.8rem 2rem;
    font-size: 1.15rem;
    text-transform: capitalize;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  &__form {
    @include standartBlock($align: space-between);
    gap: 1.5rem;

    &__row {
      @include standartBlock($direction: row);
      background: none;

      &__title {
        font-size: 1.29rem;
        font-weight: 500;
        color: $boldHeaderFont;
      }

      select {
        width: 40%;
        padding: 0.5rem;
        border-radius: 0.4rem;
        border: 1px solid $formBorder;
        background: none;

        &:focus-visible {
          border: 1px solid var(--primary-color);
          outline: none;
        }
      }

      &__fileInput {
        @include standartBlock($width: 15%);
        background: $tableHeaderBackground;
        font-size: 1.15rem;
        font-weight: 500;
        color: $boldHeaderFont;
        padding: 1rem;
        border-radius: 4px;
        cursor: pointer;

        input {
          display: none;
        }
      }
    }
  }
}
.addNewProjectWrapper {
  .nameInputWrapper {
    input {
      align-items: center;
      width: 100%;
      box-sizing: border-box;
      padding: 0;
      margin: 0;
      padding: 1rem;
      border-radius: 0.5rem !important;
      border: 1px solid rgba(40, 40, 40, 0.2);
      font-size: 1.15rem;
      font-weight: 400;
      color: #282828;
      height: 38px;

      &:focus-visible {
        outline: 1px solid var(--primary-color);
        border-color: var(--primary-color);
      }
    }
  }
}
