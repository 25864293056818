@use '../../../styles' as *;

.instagram {
  @include standartBlock($width: 30%);
  padding: 1.15rem 0;
  max-height: 100vh;
  overflow: auto;
  background: white;
  border-radius: 0.6rem;
  z-index: 110;
  gap: 0.5rem;

  &__top {
    @include standartBlock($direction: row);
    padding: 0 1rem;

    &__leftBlock {
      @include standartBlock($direction: row, $width: auto, $justify: center);
      gap: 1rem;

      &__imgBlock {
        display: flex;
        position: relative;
        width: 3rem;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100%;
          object-fit: cover;
        }
      }

      &__info {
        position: relative;
        display: flex;
        flex-direction: column;

        &__title {
          font-size: 0.9rem;
          font-weight: 400;
          color: $boldHeaderFont;
        }

        p {
          font-size: 0.8rem;
          font-weight: 400;
          color: $boldHeaderFont;
        }
      }
    }

    &__rightBlock {
      position: relative;
      display: flex;
      transform: rotate(90deg);

      &__svg {
        width: 2rem;
        height: 2rem;
      }
    }
  }

  &__slider {
    @include standartBlock();
    gap: 0.5rem;

    &__central {
      @include standartBlock($width: 100%, $align: flex-start);
      overflow: hidden;

      &__leftBtn {
        position: absolute;
        left: 2%;
        top: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        padding: 0;
        border: none;
        border-radius: 50%;
        background: rgba($color: #fff, $alpha: 0.4);
        cursor: pointer;
        transform: translate(0, -50%) rotate(90deg);
        z-index: 150;

        &__svg {
          width: 3rem;
          height: 3rem;
        }
      }

      &__rightBtn {
        position: absolute;
        right: 2%;
        top: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        padding: 0;
        border: none;
        border-radius: 50%;
        background: rgba($color: #fff, $alpha: 0.4);
        cursor: pointer;
        transform: translate(0, -50%) rotate(-90deg);
        z-index: 150;

        &__svg {
          width: 3rem;
          height: 3rem;
        }
      }

      &__stripe {
        @include standartBlock($direction: row);
        position: inherit;
        left: 0;
        transition: left 0.3s linear;

        &__imgBlock {
          position: relative;
          display: flex;
          width: 100%;

          img {
            width: 100%;
            object-fit: cover;
          }
        }
      }
    }

    &__bottom {
      @include standartBlock($direction: row);
      padding: 0 1rem;

      &__leftBlock,
      &__rightBlock {
        position: relative;
        display: flex;
        gap: 0.5rem;

        &__svg {
          height: 1.7rem;
          width: 1.7rem;
        }
      }

      &__centralBlock {
        position: absolute;
        top: 50%;
        left: 50%;
        display: flex;
        transform: translate(-50%, -50%);
        gap: 0.4rem;

        &__item {
          width: 0.6rem;
          height: 0.6rem;
          border-radius: 50%;
          background: $formBorder;
        }

        .active {
          background: var(--primary-color);
        }
      }
    }
  }

  &__bottom {
    @include standartBlock($align: flex-start);
    padding: 0 1rem;
    gap: 0.5rem;

    &__title,
    p {
      font-size: 0.9rem;
      font-weight: 400;
      color: $boldHeaderFont;
    }

    &__viewComments {
      font-size: 0.9rem;
      font-weight: 400;
      color: $headerFont;
    }
  }
}
