.docs__navbar {
  overflow: hidden;
  background-color: #0098f1;
  color: #333;
  top: 0;
  width: 100%;
  height: 70px;
  font-size: 15px;
  text-align: right;
  padding: 12px 0 0 0;
  position: fixed;
  z-index: 1;
}

.docs__navbar a {
  margin-right: 40px;
  color: #333;
}

.docs__navbar a:active {
  text-decoration: underline;
}

/* .right a {
  float: right;
} */

.docs__container {
  width: 100%;
}

/* .top {
  background-color: #0098f1;
  height: 650px;
} */

.docs__clear {
  background-color: #fff;
  height: auto;
}

/* .nav-top {
  border-top: 8px solid #0098f1;
} */

.docs__row {
  max-width: 63rem;
  margin: auto;
  display: block;
}

.docs__content {
  padding: 22px;
}

.docs__wrapper {
  display: flex;
  padding: 22px;
}

.docs__sidepanel {
  width: 200px;
  margin-top: 120px;
  background-color: #fff;
  overflow-x: hidden;
  position: fixed;
  height: 80%;
}

.docs__sidepanel a.title {
  font-family: 'Noto Serif', serif;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  text-decoration: none;
  color: #333;
  display: block;
  padding-bottom: 12px;
}

.docs__sidepanel a.docs__section {
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  text-align: left;
  line-height: 30px;
  display: block;
  text-decoration: none;
  color: #333;
}

.docs__sidepanel a.sub-docs__section {
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  text-align: left;
  line-height: 25px;
  display: block;
  text-decoration: none;
  color: #333;
  padding-left: 20px;
}

.docs__sidepanel a.title:hover,
.docs__sidepanel a.docs__section:hover,
.docs__sidepanel a.sub-docs__section:hover {
  text-decoration: underline;
}

/* .main-col {
  width: 70%;
  margin: auto;
  text-align: center;
}

.main-col p {
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 36px;
} */

.docs__right-col {
  margin-top: 100px;
  text-align: left;
  width: 100%;
  margin-left: 220px;
}

.docs__right-col h1 {
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 24px;
}

.docs__right-col h2 {
  font-size: 21px;
  line-height: 24px;
  margin-bottom: 30px;
  text-align: left;
  margin-block-start: 20px;
  margin-block-end: 10px;
}

.docs__right-col p {
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 36px;
}

.docs__right-col a {
  color: #0098f1;
  text-decoration: underline;
}

.docs__right-col ol {
  margin-block-start: 10px;
}

.docs__logo {
  width: 150px;
  float: left;
  margin: -10px 30px;
}

/* .col {
  width: 50%;
  float: left;
} */

/* .top-info {
  font-family: 'Open Sans', sans-serif;
  font-size: 17px;
  text-align: center;
} */

.docs__divider {
  width: 40px;
  height: 4px;
  background: #0098f1;
  border-radius: 30px;
  margin: auto;
  margin-top: 8px;
  margin-bottom: 8px;
}

.docs__left {
  margin: 20px 0;
}

/* .footer {
  float: left;
  width: 100%;
  padding: 90px 0 20px;
  font-size: 15px;
  text-align: center;
  background-color: #fff;
}

.footer a {
  color: #333;
} */

/* .resp-break {
  display: none;
}

.break {
  display: block;
} */

.docs__space {
  height: 30px;
  width: 100%;
  float: left;
}

.docs__double {
  height: 60px;
}

/* .links {
  float: left;
  padding-bottom: 8px;
} */
/* 
@media only screen and (max-width: 420px) {
  .docs__logo {
    width: 100px;
  }

  .docs__navbar {
    height: auto;
  }

  h2 {
    font-size: 24px;
  }

  .links {
    float: none;
  }
}


@media only screen and (max-width: 650px) {
  .docs__logo {
    width: 115px;
    margin: -10px 0px;
  }

  .docs__navbar a {
    padding-bottom: 5px;
    margin-left: 40px;
    margin-right: 0;
  }

  .resp-break {
    display: block;
    margin: 17px;
  }

  .main-col {
    width: 100%;
  }

  .docs__wrapper {
    display: block;
  }

  .docs__sidepanel {
    width: 100%;
    text-align: center;
    position: relative;
    height: auto;
  }

  .docs__sidepanel a.title,
  .docs__sidepanel a.docs__section,
  .docs__sidepanel a.sub-docs__section {
    text-align: center;
  }

  .left {
    margin: 20px auto;
  }

  .docs__right-col {
    position: relative;
    margin-top: 20px;
    margin-left: 0px;
  }
}


@media only screen and (min-width: 650px) {
  .docs__logo {
    margin: -10px 0px;
  }

  .right a {
    float: right;
    padding-bottom: 5px;
  }
}

@media only screen and (min-width: 992px) {
} */

/* End Break Points */
