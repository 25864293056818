@use '../../styles' as *;

.quickAccess {
  @include standartBlock($align: flex-start);
  gap: 1.5rem;

  &__title {
    font-size: 1.8rem;
    font-weight: 500;
    color: $boldHeaderFont;
  }

  &__row {
    @include standartBlock($direction: row);

    &__item {
      border: 1px solid rgba(40, 40, 40, 0.15);
      border-radius: 24px;
      padding: 1.45rem;

      &:nth-child(1) {
        [class*='__btn'] {
          background: #9dc8ee;
        }
      }

      &:nth-child(2) {
        [class*='__btn'] {
          background: #8fd7bd;
        }
      }

      &:nth-child(3) {
        [class*='__btn'] {
          background: #e4ed96;
        }
      }

      &:nth-child(4) {
        [class*='__btn'] {
          background: #ffaf95;
        }
      }

      &__title {
        font-size: 1.3rem;
        font-weight: 500;
        color: $boldHeaderFont;
      }

      &__description {
        font-size: 1.18rem;
        font-weight: 400;
        color: $headerFont;
      }

      &__bottomBlock {
        @include standartBlock($direction: row, $justify: flex-end);

        &__btn {
          @include standartBlock($width: auto, $direction: row);
          font-size: 1rem;
          font-weight: 500;
          color: $boldHeaderFont;
          padding: 0.9rem 1.7rem;
          border-radius: 2.3rem;
        }
      }
    }
  }
}
