@use '../../styles/' as *;

.verify {
  @include standartBlock($width: 30%, $justify: center);
  padding: 5rem 2rem;
  border-radius: 0.5rem;
  z-index: 101;
  background: $modalBackground;
  gap: 1.5rem;

  p {
    font-size: 1.29rem;
    font-weight: 500;
    color: $headerFont;
    text-align: center;
  }
}
