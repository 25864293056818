@use '../../../styles' as *;

.tooltip {
  @include standartBlock();
  background: var(--primary-color) !important;
  border-radius: 24px !important;
  color: $modalBackground !important;
  font-size: 1.15rem;
  font-weight: 500;
  padding: 1rem 2.29rem;
  border: none !important;
  position: absolute !important;
  max-width: 20rem;
}
.tooltip_icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #000 !important;
  width: 1.2rem !important;
  height: 1.2rem !important;
  border: 1px solid gray;
  margin-left: 0.5rem;
  border-radius: 50%;
}
.tooltip_active {
  opacity: 1 !important;
  z-index: 100;
}

.tooltip_hidden {
  opacity: 0 !important;
}
