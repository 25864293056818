@use '../../../styles' as *;

.facebook {
  @include standartBlock($width: 30%);
  padding: 1.15rem 0;
  max-height: 100vh;
  overflow: auto;
  background: white;
  border-radius: 0.6rem;
  z-index: 110;
  gap: 1rem;

  &__top {
    @include standartBlock($direction: row, $align: flex-start);
    padding: 0 1rem;

    &__leftBlock {
      @include standartBlock($direction: row, $width: auto, $justify: center);
      gap: 1rem;

      &__imgBlock {
        display: flex;
        position: relative;
        width: 3rem;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100%;
          object-fit: cover;
        }
      }

      &__info {
        position: relative;
        display: flex;
        flex-direction: column;

        &__title {
          font-size: 1rem;
          font-weight: 500;
          color: $boldHeaderFont;
        }

        &__subtitle {
          position: relative;
          display: flex;
          align-items: center;
          font-size: 0.8rem;
          font-weight: 400;
          color: $headerFont;
          gap: 0.3rem;

          &__separatorSvg {
            width: 0.2rem;
            height: 0.2rem;
          }

          &__svg {
            width: 1rem;
            height: 1rem;
          }
        }
      }
    }
  }

  &__text {
    @include standartBlock($align: flex-start);
    padding: 0 1rem;
    gap: 0.2rem;

    p {
      font-size: 0.9rem;
      font-weight: 400;
      color: $boldHeaderFont;
    }

    &__hashtags {
      font-size: 0.9rem;
      font-weight: 400;
      color: var(--primary-color);
    }
  }

  &__slider {
    @include standartBlock();
    gap: 0.5rem;

    &__central {
      @include standartBlock($width: 100%, $align: flex-start);
      overflow: hidden;

      &__leftBtn {
        position: absolute;
        left: 2%;
        top: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        padding: 0;
        border: none;
        border-radius: 50%;
        background: rgba($color: #fff, $alpha: 0.4);
        cursor: pointer;
        transform: translate(0, -50%) rotate(90deg);
        z-index: 150;

        &__svg {
          width: 3rem;
          height: 3rem;
        }
      }

      &__rightBtn {
        position: absolute;
        right: 2%;
        top: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        padding: 0;
        border: none;
        border-radius: 50%;
        background: rgba($color: #fff, $alpha: 0.4);
        cursor: pointer;
        transform: translate(0, -50%) rotate(-90deg);
        z-index: 150;

        &__svg {
          width: 3rem;
          height: 3rem;
        }
      }

      &__stripe {
        @include standartBlock($direction: row);
        position: inherit;
        left: 0;
        transition: left 0.3s linear;

        &__imgBlock {
          position: relative;
          display: flex;
          width: 100%;

          img {
            width: 100%;
            object-fit: cover;
          }
        }
      }
    }

    &__bottom {
      @include standartBlock($direction: row);
      padding: 0 1rem;

      &__row {
        @include standartBlock($direction: row);
        padding: 0.5rem 0;
        gap: 0.5rem;
        border-bottom: 0.5px solid $formBorder;

        &__leftBlock {
          position: relative;
          display: flex;
          font-size: 1rem;
          font-weight: 400;
          color: $headerFont;
          gap: 0.5rem;

          &__images {
            position: relative;
            display: flex;

            &__svg {
              height: 1.15rem;
              width: 1.15rem;
            }
          }
        }

        &__rightBlock {
          position: relative;
          display: flex;
          font-size: 1rem;
          font-weight: 400;
          color: $headerFont;
          gap: 1rem;
        }
      }
    }
  }

  &__bottom {
    @include standartBlock($direction: row, $justify: center);
    padding: 0 1rem;
    gap: 3rem;

    &__item {
      position: relative;
      display: flex;
      align-items: center;
      gap: 0.3rem;
      font-size: 1rem;
      font-weight: 500;
      color: $headerFont;

      &__svg {
        width: 1.15rem;
        height: 1.15rem;
      }
    }
  }
}
