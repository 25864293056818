@use '../../../styles' as *;

.combined {
  gap: 1.5rem;

  &__row {
    &__item {
      @include standartBlock($width: auto, $align: flex-start);
      padding: 1.74rem;
      border-radius: 24px;
      border: 1px solid $cellBorder;
      gap: 2rem;
      height: 100%;
      &__topBlock {
        @include standartBlock($align: flex-start);
        gap: 1rem;
        &__title {
          font-size: 1.29rem;
          font-weight: 500;
          color: $boldHeaderFont;
        }

        p {
          font-size: 1.15rem;
          color: $headerFont;
        }
      }

      &__bottomBlock {
        @include standartBlock($direction: row);
        a {
          // @include standartBlock($width: 50%);

          button {
            color: $boldHeaderFont;
            border-radius: 32px;
            font-size: 1.15rem;
            font-weight: 500;
            padding: 1rem 2.29rem;
            border: none;
            width: 125px;
          }
        }

        &__imgBlock {
          display: flex;
          position: relative;

          .svg {
            position: relative;
            width: 1.7rem;
            height: 1.7rem;
            z-index: 5;
          }
        }
      }
    }
  }

  // &__item {
  //   @include standartBlock($width: auto, $align: flex-start);
  //   padding: 1.74rem;
  //   border-radius: 24px;
  //   border: 1px solid $cellBorder;
  //   gap: 2rem;

  //   &__imgBlock {
  //     @include standartBlock($width: auto, $align: flex-start, $justify: flex-start);
  //     padding: 1.45rem;
  //     background: $marketingIconsBckgr;
  //     border-radius: 12px;
  //   }

  //   &__bottomBlock {
  //     @include standartBlock($align: flex-start);
  //     gap: 0.6rem;

  //     &__title {
  //       font-size: 1.29rem;
  //       font-weight: 500;
  //       color: $boldHeaderFont;
  //       text-transform: capitalize;
  //     }

  //     p {
  //       font-size: 1.15rem;
  //       color: $headerFont;
  //     }
  //   }
  // }
}
