@use '../../../styles/' as *;

.import {
  @include standartBlock($width: auto);

  &__btn {
    @include standartBlock($direction: row, $width: auto, $justify: flex-start);
    padding: 0.8rem 1rem;
    font-size: 1.15rem;
    text-transform: capitalize;
    font-weight: 500;
    border: none;
    border-radius: 4px;
    gap: 1rem;
    background: $boldHeaderFont;
    color: $modalBackground;
    cursor: pointer;

    input {
      display: none;
    }
  }
}
