@use '../../../styles' as *;

::-webkit-scrollbar {
  width: 0.4em;
  height: 0.4rem;
}

::-webkit-scrollbar-track {
  background-color: #ddedf7;
}
::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(253, 249, 249, 0.75);
  background-color: var(--primary-color);
}

.combined {
  flex-direction: row;
  gap: 2rem;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 3.5rem;

  &__leftBlock {
    @include standartBlock($width: 100%, $align: flex-start, $justify: flex-start);
    gap: 2rem;

    &__universalTopBlock {
      padding-bottom: 2rem;
    }

    &__drafts {
      @include standartBlock();

      &__previewBtn {
        position: absolute;
        bottom: 0.5rem;
        right: 1.5rem;
        flex-direction: row;
        padding: 0.5rem 1rem;
        width: auto;
        text-transform: capitalize;
        font-size: 1.15rem;
        z-index: 150;
      }
    }
  }

  &__editor {
    @include standartBlock($width: 100%, $justify: flex-start);
    padding: 1.5rem;
    border: 1px solid $cellBorder;
    border-radius: 24px;
    z-index: 10;
    overflow: hidden;

    &__topBlock {
      @include standartBlock($direction: row);
      padding: 1rem;

      &__title {
        font-size: 1.7rem;
        font-weight: 500;
        color: $boldHeaderFont;
      }

      button {
        position: relative;
        display: flex;
        width: auto;
        padding: 0;
        margin: 0;
        border: 0;
        background: none;
      }

      &__svg {
        width: 1.7rem;
        height: 1.7rem;
      }
    }

    &__runBtn {
      position: absolute;
      bottom: 1.5rem;
      right: 1.5rem;
      flex-direction: row;
      padding: 0.5rem 1rem;
      width: auto;
      text-transform: capitalize;
      font-size: 1.15rem;
      z-index: 1001;

      &__svg {
        width: 1.7rem;
        height: 1.7rem;
        color: $modalBackground;
      }
    }
  }
}

.combined__editor__topBlock__svg:hover {
  border: 1px solid var(--primary-color);
  padding: 1px;
  transition: 0.3s;
  border-radius: 0%;
}
