@use '../../styles' as *;
::-webkit-scrollbar {
  width: 0.4em;
  height: 0.4rem;
}

::-webkit-scrollbar-track {
  background-color: #ddedf7;
}
::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(253, 249, 249, 0.75);
  background-color: var(--primary-color);
}

.chat__header__rightBlock {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
}
.chat {
  // gap: 2rem;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 3.5rem;

  &__alignLeft {
    align-items: flex-start;

    .chat__centralBlock {
      justify-content: space-between;
      overflow: auto;
    }

    .chat__centralBlock__editor {
      display: flex;
      width: 49%;
      @media (max-width: 575px) {
        min-width: 350px;
      }
    }
  }

  &__narrow {
    align-items: flex-start;
    .chat__centralBlock {
      justify-content: space-between;

      &__history {
        width: 25%;
      }
    }

    .chat__centralBlock__editor {
      display: flex;
      width: 74%;
      @media (max-width: 575px) {
        min-width: 300px;
      }
    }
  }

  &__centralBlock {
    @include standartBlock($direction: row, $align: flex-start, $justify: center);
    gap: 1rem;
    color: $boldHeaderFont;
    font-weight: 400;
    line-height: 1.625;
    height: calc(100vh - 12rem);
    @media (max-width: 575px) {
      height: auto;
    }

    &__history {
      @include standartBlock($justify: flex-start);
      max-width: 50%;
      height: 100%;
      gap: 2rem;
      overflow: auto;
      scrollbar-width: thin; /* Firefox */
      scrollbar-color: #3498db transparent; /* Firefox */
      @media (max-width: 768px) {
        max-width: 100%;
      }
      @media (max-width: 575px) {
        min-width: 350px;
      }
      &__item {
        @include standartBlock();
        gap: 1rem;

        &__question,
        &__answer {
          @include standartBlock($direction: row, $align: flex-start);
          gap: 1rem;

          &__imgBlock {
            position: relative;
            margin-top: 0.8rem;
            display: flex;
            width: 3rem;
            border-radius: 50%;
            overflow: hidden;

            img {
              width: 100%;
              object-fit: cover;
            }
          }

          &__text {
            @include standartBlock($align: flex-start);
            padding: 1rem;
            border: 1px solid $cellBorder;
            border-radius: 24px;
            word-break: normal;
            // overflow: auto;
            gap: 2rem;

            span {
              font-size: 1.29rem;
              color: $boldHeaderFont;
              font-weight: 400;
            }

            &__btns {
              @include standartBlock($direction: row, $justify: flex-end);
              gap: 1rem;

              button {
                width: 2rem;
                padding: 0;
                background: none;
                border: none;
              }

              .svg {
                width: 24px;
                height: 24px;
              }
            }
          }
        }

        &__answer {
          &__text {
            background: linear-gradient(
                0deg,
                rgba(36, 104, 255, 0.04) 0%,
                rgba(36, 104, 255, 0.04) 100%
              ),
              #fff;

            &__prettyAnswer {
              @include standartBlock($align: flex-start);
              gap: 0.5rem;

              p {
                font-size: 1.29rem;
                color: $boldHeaderFont;
                font-weight: 400;
                line-height: 1.5;
              }
            }
          }
        }
      }

      &__emptyHistory {
        @include standartBlock($justify: center);
        height: 100%;
        gap: 2rem;
        @media (max-width: 991px) {
          @include standartBlock($justify: start);
        }

        &__preformed_requests {
          // display: flex;
          // justify-content: space-between;
          // gap: 1rem;

          &__item {
            // margin: 1rem;
            font-family: 'Roboto';
            border-radius: 16px;
            border: 1px solid rgba(40, 40, 40, 0.15);
            padding-block: 1.25rem;
            padding-inline: 1.5rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 0.5rem;
            @media (max-width: 991px) {
              height: 100%;
            }
            // margin: 1rem;
            cursor: pointer;
            &__title {
              font-size: 1.4rem;
              font-weight: 500;
            }

            &__description {
              font-size: 1.2rem;
              font-weight: 300;
            }
          }
        }
        &__imgBlock {
          position: relative;
          display: flex;
          width: 40%;

          img {
            width: 100%;
            object-fit: cover;
          }
        }

        &__title {
          font-size: 2rem;
          font-weight: 500;
          color: $boldHeaderFont;
        }
      }
    }

    &__editor {
      @include standartBlock($justify: flex-start);
      padding: 1.5rem;
      height: 100%;
      display: none;
      border: 1px solid $cellBorder;
      border-radius: 24px;
      z-index: 10;
      overflow: hidden;

      &__topBlock {
        @include standartBlock($direction: row);
        padding: 1rem;

        button {
          position: relative;
          display: flex;
          width: auto;
          padding: 0;
          margin: 0;
          border: 0;
          background: none;

          .svg1 {
            width: 1.7rem;
            height: 1.7rem;
          }
          .svg2 {
            width: 17px;
            height: 17px;
          }

          .svgRevert {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}
