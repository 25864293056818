@use '../../../styles' as *;

.images {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 3.5rem;
  @media (max-width: 575px) {
    padding: 16px 8px;
  }
  &__leftBlock {
    @include standartBlock($width: 100%, $align: flex-start, $justify: flex-start);
    gap: 2rem;

    &__universalTopBlock {
      padding-bottom: 2rem;
    }
  }

  .downloadBtn {
    background: rgba(0, 0, 0, 0.4);
    border: none;
    border-radius: 50%;
    padding: 0.3rem;
    color: white;
    cursor: pointer;
    position: absolute;
    height: 48px;
    width: 48px;
    top: 24px;
    right: 24px;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: rgba(14, 13, 13, 0.4);
      transition: 0.3s all;
    }
  }
}
