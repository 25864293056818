@use '../../../styles/' as *;

.form {
  @include standartBlock();

  table {
    position: relative;
    width: 100%;
    // min-width: 2500px;
    border-radius: 23px;
    border: 1px solid rgba(40, 40, 40, 0.5);
    border-spacing: 0;
    border-collapse: collapse;

    td {
      vertical-align: top;
      text-align: center;
      font-size: 1.15rem;
      color: $boldHeaderFont;
      font-weight: 400;
      padding: 0;
      border: 1px solid rgba(40, 40, 40, 0.5);

      textarea,
      select {
        @include standartBlock();
        // padding: 0.9rem;
        padding-top: 1.47rem;
        padding-bottom: 0.75rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        border: none;
        font-family: 'Roboto';
        font-size: 1.15rem;
        font-weight: 400;
        color: $boldHeaderFont;
        border-radius: 4px;
        resize: none;

        &::placeholder {
          font-size: 1.15rem;
          transform: translateY(0%);
        }

        &:focus-visible {
          outline: 1px solid var(--primary-color);
        }
      }

      .fileInput {
        @include standartBlock();
        background: $formBorder;
        font-size: 1.15rem;
        font-weight: 500;
        color: $boldHeaderFont;
        padding: 1.8rem;
        border-radius: 4px;
        cursor: pointer;

        input {
          display: none;
        }
      }

      .selectBlock {
        @include standartBlock();

        select {
          padding: 1.47rem 0.5rem;
          text-transform: capitalize;
          background: none;
          appearance: none;
          z-index: 10;
          overflow: visible;
        }

        &__imgBlock {
          display: flex;
          position: absolute;
          top: 50%;
          left: 90%;
          transform: translate(-50%, -50%);
          z-index: 5;

          &__svg {
            width: 1rem;
            height: 1rem;
          }
        }
      }
    }

    .generateButton {
      position: relative;

      button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.5rem;
        height: 2.5rem;
        border: none;
        border-radius: 0.4rem;
        padding: 0;
        margin: 0;
        line-height: 1;
        background: var(--primary-color);

        &:disabled {
          background: $formBorder;
          cursor: inherit;
        }
      }

      &__svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    .checkbox {
      vertical-align: middle;
    }

    .result__td {
      width: 650px;
    }

    .text {
      @include standartBlock($direction: row, $align: flex-start);
      padding: 1.2rem 0.5rem;
      max-height: 3.8rem;
      overflow: hidden;
      background: $tableRowBackground;

      &__btnsBlock {
        @include standartBlock($direction: row, $width: auto, $justify: center);
        height: 3.2rem;
        gap: 0.5rem;

        &__btn {
          @include standartBlock($width: auto);
          background: none;
          border: none;

          &__svg {
            width: 2rem;
            height: 2rem;
            color: $formBorder;
            transition: background-color 0.3s; // Add a smooth transition effect
            border: 1px solid white;
            border-radius: 10%;
            padding: 0.2rem; // Add padding to the border
            box-sizing: border-box; // Include padding in the total width/height
            &:hover {
              border-color: blue;
            }
          }
        }
      }
    }

    .images {
      @include standartBlock($direction: row);

      &__imgBlock {
        position: relative;
        display: flex;
        width: 50%;

        img {
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

.animated {
  animation: rotation linear 1s infinite;
}

@keyframes rotation {
  100% {
    transform: rotate(360deg);
  }
}