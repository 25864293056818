@use '../../../styles' as *;

.bulk {
  gap: 2rem;

  &__btnsBlock {
    @include standartBlock($direction: row, $justify: flex-end);
    gap: 1rem;

    &__runAll {
      @include standartBlock($direction: row, $width: auto, $justify: flex-start);
      padding: 0.8rem 1rem;
      font-size: 1.15rem;
      text-transform: capitalize;
      font-weight: 500;
      border: none;
      border-radius: 4px;
      gap: 1rem;
      background: $activeNav;
      color: $modalBackground;
    }
    &__addBtn {
      @include standartBlock($direction: row, $width: auto, $justify: flex-start);
      padding: 0.8rem 1rem;
      font-size: 1.15rem;
      text-transform: capitalize;
      font-weight: 500;
      border: none;
      border-radius: 4px;
      gap: 1rem;
      background: $activeNav;
      color: $modalBackground;
    }
  }

  // Placeholder styles for text area
  textarea {
    &::placeholder {
      font-size: 1.15rem;
      transform: translateY(40%);
    }
  }

  // Placeholder styles for select
  td {
    > div > select {
      font-size: 1.15rem !important; // Set font size inline for the select element
    }
  }

  &__table {
    @include standartBlock();

    // border-radius: 23px;
    overflow-x: auto;
    // width: 100%;
    // max-width: 100%;
    border: 1px solid rgba(40, 40, 40, 0.1);
    &::-webkit-scrollbar {
      height: 7px;
    }

    /* Set the color of the thumb (the draggable part of the scrollbar) */
    &::-webkit-scrollbar-thumb {
      background-color: #c9c9c99d;
    }

    /* Optional: Set the color of the track (the area where the thumb moves) */
    &::-webkit-scrollbar-track {
      background-color: white;
    }

    &__cont {
      width: 100%;
    }

    &__item {
      @include standartBlock();


      min-width: 2500px;
      &:not(:first-child) {
        form table {
          thead tr th {
            padding: 0rem 0.5rem;
            font-size: 0rem;
            background: none;
            height: 1px !important;
            border-bottom: 1px solid rgba(40, 40, 40, 0.1);
          }

          thead{

          }

        }
      }
    }
  }

  &__addBtn {
      display: flex;
      align-items: center;
      padding: 10px 28px;
      font-size: 1.15rem;
      text-transform: capitalize;
      font-weight: 500;
      border-radius: 4px;
      gap: 1rem;
      color: #ffff;
      cursor: pointer;
  }
}
.runAll_btn{
  display: flex;
  align-items: center;
  padding: 10px 16px;
  font-size: 1.15rem;
  text-transform: capitalize;
  font-weight: 500;
  border-radius: 4px;
  gap: 1rem;
  color: black;
  cursor: pointer;
  background: #ffff;
}
.cTableWrapper{
  border: 1px solid var(--Primary-P-100, #DAE4FF);
  border-radius: 8px;
  table{
    overflow: hidden;
    border-radius: 8px;
    border: none;
    margin-bottom: 0;
  }


  th{
    background: #DAE4FF;
    padding: 16px 24px;
    color: #111827;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    border-bottom: none;

  }
  tbody{
    tr{
      td{
        padding: 12px 24px;
        border-bottom: none;
        color: #374151;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }
  select{
    outline: none !important;
    box-shadow: none !important;
    height: 44.94px;
  }
  input{
    outline: none;
    border-radius: 8px;
  }
}
.cDropdownCheckBox{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  min-width: 16px;
  border: 1px solid #cacacc;
  border-radius: 2px;
  background-color:#fff;
  cursor: pointer;
  &.active{
    background-color: #146ef6;
  }
}
.actionBtns{
  button{
    height: 36px;
  }
}