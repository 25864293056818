/* MyEditor.css */
@use '../../../styles' as *;

.myEditor {
  @include standartBlock();
  width: 100% !important;

  .ck {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: none;

    .ck-voice-label {
      display: none !important;
    }
  }
}

.ck.ck-editor {
  flex-direction: column-reverse !important;
  width: 100% !important;
  border: none !important;
  gap: 1.5rem !important;
}

.ck-editor__top {
  display: flex !important;
  justify-content: flex-start !important;
  width: 100% !important;
  border: none !important;
}

.ck.ck-toolbar {
  border: none !important;
}

.ck.ck-button {
  width: 4% !important;
}

.editor-container {
  width: 100% !important;
}

.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
  border-radius: 24px !important;
  width: 100% !important;
}

.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  border: none !important;
}

.ck.ck-editor__main > .ck-editor__editable {
  background: linear-gradient(0deg, rgba(36, 104, 255, 0.04) 0%, rgba(36, 104, 255, 0.04) 100%),
    #fff !important;
}
.ck-editor__editable {
  box-sizing: border-box !important;
  height: calc(100vh - 22rem) !important;

  p {
    font-size: 1.29rem !important;
    line-height: 1.5 !important;
  }

  @media screen and (max-device-width: 1600px) {
    height: calc(100vh - 24rem) !important;
  }
  // max-height: calc(60vh + 3rem);
}

.ck-editor__editable {
  box-sizing: border-box !important;
  height: calc(100vh - 22rem) !important;
  overflow-y: auto;
  padding-right: 15px;
  scrollbar-width: thin;
  scrollbar-color: #3498db transparent;
}

.ck-editor__editable::-webkit-scrollbar {
  width: 10px;
  border-radius: 8px;
}

.ck-editor__editable::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(253, 249, 249, 0.75) !important;
  background-color: #2468ff !important;
  border-radius: 10px;
  border: 3px solid transparent;
}

.ck-editor__editable::-webkit-scrollbar-track {
  background-color: transparent;
}
