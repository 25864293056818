@use '../../styles/' as *;

.form {
  @include standartBlock($direction: row, $justify: flex-start);
  padding: 0 0 0 0rem;
  z-index: 10;
  max-width: 50%;
  // gap: 1rem;
  margin-top: 2rem;
  @media (max-width: 768px) {
    max-width: 100%;
    margin-bottom: 48px;
  }
  &__selectBlock {
    @include standartBlock($width: 100%, $direction: row);

    &__select {
      position: relative;
      width: 100%;
      padding: 1.4rem;
      font-size: 1.15rem;
      color: $boldHeaderFont;
      border: 1px solid $cellBorder;
      background: none;
      appearance: none;
      cursor: pointer;
      z-index: 10;
      text-transform: capitalize;

      &:focus-visible {
        outline: 1px solid var(--primary-color);
      }
    }

    &__imgBlock {
      position: absolute;
      display: flex;
      top: 50%;
      left: 87%;
      transform: translate(-50%, -50%);
      z-index: 5;

      .svg {
        width: 1.45rem;
        height: 1.45rem;
      }
    }
  }

  &__inputBlock {
    @include standartBlock($width: 100%, $direction: row);
    border: 1px solid $cellBorder;

    input {
      @include standartBlock($width: 95%);
      font-size: 1.15rem;
      color: $boldHeaderFont;
      padding: 1.4rem;
      border: none;

      &:focus-visible {
        outline: 1px solid var(--primary-color);
      }
    }

    &__btn {
      @include standartBlock($width: 8%);
      padding: 0.7rem 1rem;
      border: none;
      background: none;
      @media only screen and (max-width: 575px) {
        width: 12%;
      }

      .svg {
        width: 2rem;
        height: 2rem;
      }
    }
  }
}
