@use '../../../styles' as *;

.limit_notification {
  position: absolute;
  top: 27px;
  right: 31px;
  font-size: 1.12rem;
  border: 1.7px solid #d9d9d9;
  border-radius: 8px;
  color: #616161;
  padding: 1.2rem 1.8rem;
  z-index: 15;
  background-color: white;

  &__link {
    color: var(--primary-color);
  }

  &__close {
    cursor: pointer;
    position: absolute;
    top: -1.2rem;
    right: -0.8rem;
    color: #616161;
  }
}
