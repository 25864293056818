@use '../../../styles/' as *;

.export {
  @include standartBlock($width: auto);

  &__btn {
    @include standartBlock($direction: row, $width: auto, $justify: flex-start);
    padding: 0.8rem 1rem;
    font-size: 1.15rem;
    color: $boldHeaderFont;
    text-transform: capitalize;
    font-weight: 500;
    border: 1px solid $boldHeaderFont;
    border-radius: 4px;
    background: none;
    gap: 1rem;
  }
}
