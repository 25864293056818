@use '../../styles' as *;

.recent {
  @include standartBlock($align: flex-start);
  gap: 1.5rem;

  &__title {
    font-size: 1.8rem;
    font-weight: 500;
    color: $boldHeaderFont;
  }

  table {
    width: 100%;
    border-radius: 23px;
    overflow: hidden;
    border: 1px solid rgba(40, 40, 40, 0.15);
    border-spacing: 0;

    thead {
      background: #f3f3f3;

      th {
        font-size: 1.15rem;
        font-weight: 500;
        color: $headerFont;
        text-align: left;
        padding: 1.15rem 2.3rem;

        &:last-child {
          text-align: center;
        }
      }
    }

    tbody {
      tr:nth-child(2n) {
        background: rgba(40, 40, 40, 0.03);
      }

      td {
        font-size: 1.15rem;
        color: $boldHeaderFont;
        font-weight: 400;
        padding: 0.9rem 2.3rem;
      }

      td:nth-child(n + 4) {
        text-align: center;

        .svg {
          width: 1.45rem;
          height: 1.45rem;
        }
      }
    }
  }
}
