@use '../../styles' as *;

.plan {
  padding: 2.86rem 2.6rem 3rem calc(13.54% + 2.6rem);
  gap: 2rem;
  @media screen and (max-width: 1028px) {
    padding: 2%;
  }
  @media screen and (max-width: 768px) {
    padding: 3%;
  }
  @media screen and (max-width: 480px) {
    padding: 4%;
  }
  &__topBlock {
    @include standartBlock($direction: row);
    padding: 1.15rem 3rem;
    color: $modalBackground;
    background: $boldHeaderFont;
    border-radius: 24px;
    @media screen and (max-width: 575px) {
      flex-direction: column;
      gap: 16px;
    }

    &__headers {
      @include standartBlock($width: auto, $align: flex-start);
      gap: 0.5rem;
      @media screen and (max-width: 575px) {
        align-items: center;
      }

      &__title {
        font-size: 1.6rem;
        font-weight: 700;
      }

      p {
        font-size: 1.1rem;
        font-weight: 500;
      }
    }

    &__switcher {
      @include standartBlock($direction: row, $width: auto);
      gap: 1rem;
      @media screen and (max-width: 575px) {
        width: 100%;
      }
      &__passive {
        opacity: 0.5;
      }

      &__monthlyTitle {
        font-size: 1.08rem;
        font-weight: 700;
      }

      &__label {
        @include standartBlock($direction: row, $width: auto);
        gap: 1rem;
        cursor: pointer;

        input {
          appearance: none;
        }

        &__checkbox {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 3rem;
          height: 1.8rem;
          border-radius: 0.8rem;
          background: $progressBar;

          &__round {
            border-radius: 50%;
            width: 1.6rem;
            height: 1.6rem;
            background: $boldHeaderFont;
          }
        }

        &__checkboxPassive {
          justify-content: flex-start;
          opacity: 0.5;
        }

        &__annualTitle {
          font-size: 1.08rem;
          font-weight: 700;

          span {
            color: $progressBar;
            text-transform: uppercase;
          }
        }
      }
    }
  }

  &__table {
    // display: grid;
    width: 100%;
    // grid-template-columns: repeat(4, minmax(50px, 1fr));
    // grid-auto-flow: dense;
    // gap: 2rem;

    &__item {
      @include standartBlock($width: 100%, $justify: flex-start);
      padding: 4rem 2.5rem;
      border: 2px solid $formBorder;
      border-radius: 24px;
      height: 100%;
      gap: 2rem;
      min-height: 90vh;
      max-height: fit-content;

      &:hover {
        border: 2px solid var(--primary-color);
        background: $tableRowBackground;
      }

      &__title {
        font-size: 2.15rem;
        font-weight: 700;
        color: $boldHeaderFont;
        text-align: center;
      }

      &__subtitle {
        font-size: 1.08rem;
        font-weight: 700;
        color: $boldHeaderFont;
        text-align: center;
      }

      &__pay {
        display: flex;
        align-items: center;
        font-size: 1.08rem;
        font-weight: 500;
        color: $boldHeaderFont;
        text-align: center;
        gap: 0.5rem;

        span {
          font-size: 3.3rem;
          color: var(--primary-color);
          letter-spacing: -1.8px;

          sup {
            font-size: 2rem;
          }
        }
      }

      &__words {
        @include standartBlock($direction: row, $justify: center);
        font-size: 1.29rem;
        font-weight: 500;
        color: $boldHeaderFont;
        gap: 4rem;

        span:nth-child(2) {
          font-size: 1.08rem;
          font-weight: 400;

          opacity: 0.8;
        }
      }

      &__btn {
        @include standartBlock($width: 80%);

        button {
          padding: 1.5rem;
          font-size: 1.29rem;
          text-transform: capitalize;
        }
      }

      ul {
        @include standartBlock();
        width: 100%;
        gap: 1rem;

        li {
          @include standartBlock($direction: row, $justify: flex-start);
          font-size: 1.15rem;
          color: $listColor;
          gap: 1rem;
        }
      }
    }

    &__itemEmpty {
      align-items: center;
      justify-content: center;

      p {
        font-size: 1.33rem;
        font-weight: 400;
        color: $boldHeaderFont;
        text-align: center;
      }

      button {
        background: $boldHeaderFont;
      }
    }
  }
}
