@use '../../../styles/' as *;

.editor {
  @include standartBlock($width: 40%, $justify: flex-start);
  padding: 1.5rem;
  border: 1px solid $cellBorder;
  border-radius: 24px;
  background: $modalBackground;
  z-index: 10;
  overflow: hidden;

  p {
    font-size: 1.15rem !important;
  }
  &__topBlock {
    @include standartBlock($direction: row);
    padding: 1rem;

    &__title {
      font-size: 1.7rem;
      font-weight: 500;
      color: $boldHeaderFont;
    }

    &__btnsBlock {
      @include standartBlock($width: auto, $direction: row);
      gap: 1rem;

      button {
        position: relative;
        display: flex;
        width: auto;
        padding: 0;
        margin: 0;
        border: 0;
        background: none;
      }

      &__svg {
        width: 2rem;
        height: 2rem;
        color: $formBorder;
        transition: background-color 0.3s; // Add a smooth transition effect
        border: 1px solid white;
        border-radius: 10%;
        padding: 0.2rem; // Add padding to the border
        box-sizing: border-box; // Include padding in the total width/height
        &:hover {
          border-color: blue;
        }
      }
    }
  }

  &__runBtn {
    position: absolute;
    bottom: 1.5rem;
    right: 1.5rem;
    flex-direction: row;
    padding: 0.5rem 1rem;
    width: auto;
    text-transform: capitalize;
    font-size: 1.15rem;
    z-index: 1001;

    &__svg {
      width: 1.7rem;
      height: 1.7rem;
      color: $modalBackground;
    }
  }
}
