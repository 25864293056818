@use '../../styles' as *;

.centeredFlex {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.invite {
  position: relative;

  @include standartBlock($width: 40%);
  @media screen and (max-width: 540px) {
    min-width: 80%;
    padding: 3rem 5rem;
  }
  @media screen and (max-width: 1010px) {
    min-width: 60%;
    padding: 3rem 5rem;
  }
  @media screen and (max-width: 480px) {
    min-width: 90%;
    padding: 1.5rem 3rem;
  }
  padding: 3rem 8rem;
  border-radius: 0.5rem;
  z-index: 101;
  background: $modalBackground;
  gap: 1.5rem;

  &__title {
    font-size: 2rem;
    font-weight: 500;
    color: $boldHeaderFont;
  }

  &__subtitle {
    font-size: 1.29rem;
    font-weight: 500;
    color: $boldHeaderFont;
  }

  p {
    font-size: 1.29rem;
    font-weight: 500;
    color: $paleFont;
  }

  &__label {
    @include standartBlock();
    gap: 1rem;

    &__message {
      display: none;
      font-size: 1rem;
      font-weight: 400;
      color: green;
    }

    &__messageError {
      display: block;
      color: red;
    }

    &__messageSuccess {
      display: block;
      color: green;
    }

    input {
      @include standartBlock();
      padding: 1rem;
      border-radius: 0.5rem;
      border: 1px solid $formBorder;
      font-size: 1.15rem;
      font-weight: 400;
      color: $boldHeaderFont;

      &:focus-visible {
        border: 1px solid $modalBackground;
        outline: 2px solid var(--primary-color);
      }
    }
  }

  &__btn {
    @include standartBlock($direction: row, $justify: center);
    padding: 1rem;
    border-radius: 0.5rem;
    font-size: 1.29rem;
    font-weight: 500;
    color: var(--primary-color);
    gap: 1rem;
    background: $paleBackground;
    border: none;
    transition: all 0.2s linear;

    &:hover {
      color: $modalBackground;
      background: var(--primary-color);
    }

    svg {
      width: 1.7rem;
      height: 1.7rem;
    }
  }

  &__separator {
    @include standartBlock();
    font-size: 1.29rem;
    font-weight: 500;
    color: $paleFont;
    text-transform: uppercase;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      background: $paleFont;
      width: 100%;
      height: 0.5px;
      z-index: 5;
    }

    span {
      padding: 1rem;
      background: $modalBackground;
      z-index: 10;
    }
  }

  &__confirmedCopy {
    display: none;
    font-size: 1rem;
    font-weight: 400;
    color: green;
  }

  &__confirmedCopyVisible {
    display: block;
    animation: appearence 0.2s linear;
  }
}

@keyframes appearence {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
