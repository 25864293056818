@use '../../styles' as *;

.homepage {
  @include standartBlock($align: flex-start, $justify: flex-start);
  padding: 2.86rem 2.6rem 3rem calc(13.54% + 2.6rem);
  flex: 1 1 auto;
  gap: 3rem;

  &__title {
    font-size: 2.3rem;
    font-weight: 500;
    color: $boldHeaderFont;
  }
  // media queries
  @media screen and (max-width: 1028px) {
    padding: 2%;
  }
  @media screen and (max-width: 768px) {
    padding: 3%;
  }
  @media screen and (max-width: 480px) {
    padding: 4%;
  }
}
