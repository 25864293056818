@use '../../styles' as *;

.plan {
  @include standartBlock($align: left);
  padding: 2rem;
  border-radius: 16px;
  border: 1px solid $formBorder;
  gap: 2.5rem;

  &__title {
    font-size: 1.72rem;
    font-weight: 500;
    color: $boldHeaderFont;
  }

  &__chart {
    @include standartBlock($align: flex-start);
    padding: 1.72rem;
    border-radius: 12px;
    border: 1px solid $formBorder;
    background: $boldHeaderFont;
    color: $modalBackground;
    gap: 2rem;

    &__topBlock {
      @include standartBlock($align: flex-start);
      gap: 1rem;

      &__title {
        font-size: 1.72rem;
        font-weight: 500;
        text-transform: capitalize;
      }

      p {
        font-size: 1.43rem;
        font-weight: 400;
      }
    }

    &__row {
      @include standartBlock();
      gap: 2rem;

      &__item {
        @include standartBlock();
        gap: 0.8rem;
        font-size: 1.2rem;

        &__progress {
          @include standartBlock($align: flex-start);
          background: #ccc;
          border-radius: 5px;

          &__bar {
            display: flex;
            height: 0.5rem;
            max-width: 100%;
            background: $progressBar;
            border-radius: 6px;
            transition: width 0.3s linear;
          }
        }

        &__bottomBlock {
          @include standartBlock($direction: row);
          @media (max-width: 575px) {
            flex-direction: column;
            gap: 8px;
          }
        }
      }
    }
  }

  &__btnBlock {
    @include standartBlock($direction: row, $justify: flex-end);
    gap: 1rem;
    @media screen and (max-width: 768px) {
      justify-content: center;
    }
    button {
      width: fit-content;
      text-transform: capitalize;
      @media screen and (max-width: 768px) {
        font-size: 1rem;
      }
    }

    &__cancel {
      color: $formBorder;
      background: $cellBorder;
    }
  }
}
