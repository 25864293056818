@use '../../../styles' as *;

.hidden {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1001;
  align-items: center;
  justify-content: center;

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $headerFont;
    filter: opacity(0.7);
    cursor: pointer;
  }
}
