@use '../../styles' as *;

.company {
  @include standartBlock($justify: center);
  flex: 1 1 auto;

  &__form {
    @include standartBlock($width: 43%);
    padding: 3rem 8rem;
    border-radius: 0.5rem;
    z-index: 101;
    background: $modalBackground;
    gap: 1.5rem;

    &__title {
      font-size: 2rem;
      font-weight: 500;
      color: $boldHeaderFont;
      text-align: center;
    }

    &__label {
      @include standartBlock();
      gap: 1rem;

      &__message {
        display: none;
        font-size: 1rem;
        font-weight: 400;
        color: green;
      }

      input {
        @include standartBlock();
        padding: 1rem;
        border-radius: 0.5rem;
        border: 1px solid $formBorder;
        font-size: 1.15rem;
        font-weight: 400;
        color: $boldHeaderFont;

        &:focus-visible {
          border: 1px solid $modalBackground;
          outline: 2px solid var(--primary-color);
        }
      }
    }

    &__btn {
      @include standartBlock($direction: row, $justify: center);
      padding: 1rem;
      border-radius: 0.5rem;
      font-size: 1.29rem;
      font-weight: 500;
      color: var(--primary-color);
      gap: 1rem;
      background: $paleBackground;
      border: none;
      transition: all 0.2s linear;

      &:hover {
        color: $modalBackground;
        background: var(--primary-color);
      }
    }
  }

  @keyframes appearence {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
