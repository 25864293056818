@use '../../styles' as *;

.info {
  @include standartBlock($width: 50%, $justify: center);
  //   background: $headerBackground;
  //   flex: 1 1 auto;
  @media screen and (max-width: 1200px) {
    width: 60%;
  }
  @media screen and (max-width: 1024px) {
    width: 70%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  &__form {
    @include standartBlock($align: flex-start);
    padding: 1.72rem;
    border: 1px solid $formBorder;
    border-radius: 24px;
    gap: 2.5rem;

    &__topBlock {
      @include standartBlock($align: flex-start);
      gap: 1rem;

      &__title {
        font-size: 1.72rem;
        font-weight: 500;
        color: $boldHeaderFont;
        text-transform: capitalize;
      }

      p {
        font-size: 1.29rem;
        font-weight: 400;
        color: $boldHeaderFont;
      }
    }

    &__centralBlock {
      @include standartBlock();
      gap: 2rem;

      &__photoLabel {
        @include standartBlock($align: flex-start, $justify: flex-start);
        gap: 1rem;

        &__title {
          font-size: 1.15rem;
          font-weight: 400;
          color: $boldHeaderFont;
          text-transform: capitalize;
        }

        &__photoInput {
          @include standartBlock($direction: row, $justify: flex-start);
          gap: 1rem;

          &__imgBlock {
            position: relative;
            display: flex;
            width: 50px;

            img {
              width: 100%;
              object-fit: cover;
            }
          }

          &__fileInput {
            // @include standartBlock($width: 15%);
            background: $tableHeaderBackground;
            font-size: 1.15rem;
            font-weight: 500;
            color: $boldHeaderFont;
            padding: 1rem;
            border-radius: 4px;
            cursor: pointer;

            input {
              display: none;
            }
          }
        }
      }

      &__inputsBlock {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(2, minmax(50px, 1fr));
        grid-auto-flow: row;
        gap: 2rem;

        &__label {
          @include standartBlock($align: flex-start, $justify: flex-start);
          gap: 1rem;

          &__title {
            font-size: 1.15rem;
            font-weight: 400;
            color: $boldHeaderFont;
            text-transform: capitalize;
          }

          input {
            @include standartBlock();
            padding: 1rem;
            font-size: 1.15rem;
            font-weight: 400;
            font-family: 'Roboto';
            color: $boldHeaderFont;
            border-radius: 4px;
            border: 1px solid $formBorder;

            &:focus-visible {
              border: 1px solid $modalBackground;
              outline: 1px solid var(--primary-color);
            }
          }
        }
      }
    }

    &__btnBlock {
      @include standartBlock($direction: row, $justify: flex-end);
      gap: 2rem;
      font-size: 1rem;

      &__successMessage {
        color: green;
      }

      &__errorMessage {
        color: red;
      }

      button {
        // max-width: 20%;
        width: fit-content;
        text-transform: capitalize;
      }
    }
  }
}
