@use '../../../styles' as *;

.form {
  @include standartBlock();
  align-items: normal !important;
  gap: 2rem;
  @media (max-width: 575px) {
    gap: 16px;
  }

  &__additionalQuestions {
    @include standartBlock($align: flex-start);
    gap: 2rem;

    &__btn {
      @include standartBlock($direction: row, $width: auto);
      gap: 1rem;
      border: none;
      background: none;
      font-size: 1.29rem;
      color: $boldHeaderFont;
      font-weight: 500;
      text-transform: capitalize;
      cursor: pointer;

      &__svg {
        width: 1.7rem;
        height: 1.7rem;
        transform: rotate(-90deg);
      }

      &__svgActive {
        width: 1.7rem;
        height: 1.7rem;
        // transform: rotate(-90deg);
      }
    }
  }

  &__label {
    @include standartBlock($align: flex-start);
    gap: 1rem;

    &__title {
      font-size: 1.15rem;
      font-weight: 400;
      color: $boldHeaderFont;
      @media (max-width: 768px) {
        margin-left: 0px !important;
        margin-top: 10px;
      }
    }

    textarea,
    select {
      @include standartBlock();
      padding: 0.5rem;
      border: 1px solid $formBorder;
      font-family: 'Roboto';
      font-size: 1.15rem;
      font-weight: 400;
      color: $boldHeaderFont;
      border-radius: 4px;
      resize: vertical;

      &:focus-visible {
        outline: 1px solid var(--primary-color);
      }
      @media (max-width: 768px) {
        margin-left: 0px !important;
        width: 100% !important;
      }
    }

    &__fileInput {
      @include standartBlock();
      background: $formBorder;
      font-size: 1.15rem;
      font-weight: 500;
      color: $boldHeaderFont;
      padding: 1rem;
      border-radius: 4px;
      cursor: pointer;

      input {
        display: none;
      }
    }

    &__selectBlock {
      @include standartBlock();

      select {
        padding: 1rem 0.5rem;
        text-transform: capitalize;
        background: none;
        appearance: none;
        z-index: 10;
      }

      &__imgBlock {
        display: flex;
        position: absolute;
        top: 50%;
        left: 90%;
        transform: translate(-50%, -50%);
        z-index: 5;

        &__svg {
          width: 1.7rem;
          height: 1.7rem;
        }
      }
    }
  }

  &__labelCheckbox {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  &__splitTwo {
    @include standartBlock($direction: row);
    flex-wrap: wrap;

    .form__label {
      width: 48%;
    }
  }
}

.required {
  color: red;
}
.form__label__fileInput {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  padding: 32px 24px;
  border: 1px solid rgba(40, 40, 40, 0.2);
  border-radius: 12px;
  background-color: #ffffff;
  text-align: center;
  cursor: pointer;
  transition: border-color 0.3s ease, background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    border-color: #007bff;
    // background-color: #e6f7ff;
    padding: 32px 24px;
    transform: scale(1.02);
  }
  &uploaded {
    border-color: #007bff;
    background-color: #e6f7ff;
  }

  svg {
    transition: fill 0.3s ease, transform 0.3s ease;
  }

  input[type='file'] {
    display: none;
  }

  &::after {
    content: attr(data-placeholder);
    position: absolute;
    top: calc(100% - 24px);
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1rem;
    color: #aaa;
  }

  &.has-file {
    background-color: #e6ffe6;
    border-color: #28a745;

    svg {
      fill: #28a745;
      transform: rotate(360deg);
    }
  }
}
.c-cancel-btn {
  position: absolute !important;
  right: 0 !important;
  top: 24px;
  background: red;
  border-radius: 12px;
}
