@use '../../styles' as *;

.recent {
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 16px 24px 24px 24px;
  }

  &__title {
    font-size: 2.29rem;
    font-weight: 500;
    color: $boldHeaderFont;
    margin-bottom: 1rem;
  }
  &__subHeader {
    font-size: 1.29rem;
    font-weight: 500;
    color: $boldHeaderFont;
  }

  table {
    width: 100%;
    border-radius: 23px;
    overflow: hidden;
    border: 1px solid rgba(40, 40, 40, 0.15);
    border-spacing: 0;

    thead {
      background: #f3f3f3;
      white-space: nowrap;

      th {
        font-size: 1.15rem;
        font-weight: 500;
        color: $headerFont;
        text-align: left;
        padding: 1.15rem 2.3rem;

        &:last-child {
          text-align: center;
        }
      }
    }

    tbody {
      tr:nth-child(2n) {
        background: rgba(40, 40, 40, 0.03);
      }

      td {
        font-size: 1.15rem;
        color: $boldHeaderFont;
        font-weight: 400;
        padding: 0.9rem 2.3rem;
      }

      td:nth-child(n + 4) {
        text-align: center;

        .svg {
          width: 1.45rem;
          height: 1.45rem;
        }
      }
    }
  }
}
