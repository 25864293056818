@use './styles' as *;

html,
body {
  margin: 0;
  font-family: 'Roboto', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.728vw;
  line-height: 1.2;
  scroll-behavior: smooth;

  @media screen and (max-device-width: $notepad) {
    font-size: 1.5vw;
  }

  @media screen and (max-device-width: $tablet) {
    font-size: 2vw;
  }

  @media screen and (max-device-width: $mobile) {
    font-size: 3vw;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote {
  margin: 0;
  padding: 0;
  line-height: 1.2;
}

a {
  text-decoration: none;
  color: inherit;
  margin: 0;
  padding: 0;
  line-height: 1em;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

button {
  font-family: 'Roboto', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  cursor: pointer;
  line-height: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.app {
  @include standartBlock();
  min-height: 100vh;
  background-color: var(--background-color);
}

.appAuth {
  @include standartBlock($justify: flex-start);
  min-height: 100vh;
}

@keyframes slideFromLeft {
  0% {
    left: -100%;
  }
  100% {
    left: 0;
  }
}

/*UNIVERSAL CLASSES*/
.universalPage {
  @include standartBlock($justify: flex-start);
  padding: 2.29rem 2.29rem 2.29rem calc(4% + 2.29rem);
  @media screen and (max-width: 1028px) {
    padding: 2%;
  }
  @media screen and (max-width: 768px) {
    padding: 3%;
  }
  @media screen and (max-width: 480px) {
    padding: 4%;
  }
}

.universalTopBlock {
  @include standartBlock($align: flex-start);
  gap: 1rem;
}

.universalTopTitle {
  font-size: 2.29rem;
  font-weight: 500;
  color: $boldHeaderFont;
  text-transform: capitalize;
}

.universalBreadcrumbs {
  @include standartBlock($direction: row, $align: flex-start, $justify: flex-start);
  font-size: 1.15rem;
  font-weight: 400;
  color: $boldHeaderFont;
  gap: 0.5rem;

  &__lastChild {
    opacity: 0.5;
  }
}

.universalButton {
  @include standartBlock();
  background: var(--primary-color);
  border-radius: 32px;
  color: $modalBackground;
  font-size: 1.15rem;
  font-weight: 500;
  padding: 1rem 2.29rem;
  border: none;

  &:disabled {
    background: $formBorder !important;
    cursor: inherit;
  }
}

.universalComingSoon {
  padding: 0.2em 0.4em;
  line-height: 0.8;
  font-size: 0.6em;
  color: $modalBackground;
  background: var(--primary-color);
  border-radius: 0.7em;
}
@media screen and (min-width: 1028px) {
  .navbar-toggler-btn {
    display: none;
  }
}

.header {
  @include standartBlock($width: 4%, $align: flex-start, $justify: space-between);
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  padding: 2.3rem 1rem 3.5rem 1rem;
  color: $headerFont;
  background: $headerBackground;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 100;
  @media screen and (max-width: 575px) {
    padding-bottom: 70px;
  }
  .topBlock {
    @include standartBlock($align: flex-start, $justify: flex-start);
    gap: 3rem;

    @media screen and (max-device-width: 1600px) {
      gap: 1rem;
    }
  }

  .topBlock__logo {
    @include standartBlock($align: flex-start);
    width: 100%;
    overflow: hidden;

    img {
      width: 300%;
      object-fit: cover;
    }
  }

  .topBlock__nav {
    @include standartBlock($align: flex-start);
    padding: 1rem 0 0 0;
    gap: 2.8rem;
    @media (max-width: 575px) {
      gap: 16px;
    }

    &__group {
      @include standartBlock($align: flex-start);
      gap: 1.1rem;

      &:nth-child(2) {
        border-top: 1px solid #c4c4c4;
        border-bottom: 1px solid #c4c4c4;
        padding: 2rem 0;
      }

      &:nth-child(3) {
        border-bottom: 1px solid #c4c4c4;
        padding: 0 0 2rem 0;
      }

      &__title {
        display: none;
        font-size: 1.15rem;
        font-weight: 500;
        color: $boldHeaderFont;
        text-transform: capitalize;
      }

      ul {
        @include standartBlock($align: center);
        gap: 2.3rem;

        li {
          color: $headerFont;

          a,
          .topBlock__nav_ul_li_a {
            @include standartBlock($direction: row, $align: center, $justify: center);
            font-size: 1.28rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.85rem;
            color: $headerFont;
            padding: 0 0 0 0;
            gap: 1rem;

            span {
              line-height: 1;
              display: none;
            }

            .svg {
              width: 1.71rem;
              height: 1.71rem;
            }
          }

          a.active {
            color: var(--primary-color);
          }
        }
      }
    }
  }

  .bottomBlock {
    @include standartBlock($align: flex-end, $justify: flex-end);
    // gap: 2rem;
    padding-top: 3.5rem;
  }

  .bottomBlock__refer {
    @include standartBlock($align: flex-start);
    display: none;
    padding: 1rem 0.8rem;
    border-radius: 8px;
    background: #d9d9d9;
    gap: 0.85rem;

    &__title {
      font-size: 1rem;
      color: $boldHeaderFont;
      font-weight: 500;
    }

    span {
      @include standartBlock($direction: row, $align: space-between);
      font-size: 0.85rem;

      h4 {
        font-size: 1rem;
        font-weight: 500;
        color: var(--primary-color);
        cursor: pointer;
      }
    }
  }

  .bottomBlock__user {
    @include standartBlock($align: flex-start, $justify: felx-end);
    gap: 1rem;
    padding-top: 1rem;
    cursor: default;

    &__profile {
      @include standartBlock($align: flex-start);
      display: none;
      position: absolute;
      bottom: 4.5rem;
      padding: 1.25rem 0.5rem;
      font-size: 1.15rem;
      font-weight: 500;
      color: $boldHeaderFont;
      border: 1px solid #d9d9d9;
      border-radius: 8px;
      // border-radius: 6px;
      background: #fff;
      // background: #d9d9d9;
      gap: 1rem;
      // gap: 2rem;
      z-index: 10;
      overflow: hidden;
      @media screen and (max-width: 675px) {
        bottom: 10rem;
      }
      &__navLink {
        @include standartBlock($align: center, $direction: row, $justify: flex-start);
        color: $headerFont;
        cursor: pointer;
        gap: 0.5rem;

        &:hover {
          color: var(--primary-color);
        }
        &__text {
          font-size: 1.25rem;
        }
      }

      a.active {
        color: var(--primary-color);
      }
    }

    &__profileVisible {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      animation: appearence 0.3s linear;
    }

    &__info {
      @include standartBlock($direction: row, $justify: flex-start);
      gap: 0.8rem;
      &__imgBlock {
        display: flex;
        position: relative;
        width: 100%;

        img {
          width: 100%;
          object-fit: cover;
        }
      }

      &__card {
        @include standartBlock($width: auto, $align: flex-start);
        display: none;
        gap: 0.3rem;

        &__name {
          font-size: 1.13rem;
          font-weight: 400;
          color: $boldHeaderFont;
        }

        address {
          font-size: 0.84rem;
          font-style: normal;
        }
      }
    }
  }
}

.headerWide {
  width: 13.54%;
  padding: 2.3rem 1.49rem 3.5rem 1.49rem;
  // animation: appearence 0.5s linear;
  transition: width 0.1s linear;

  // @media screen and (max-device-width: $tablet) {
  //   padding: 2.35rem 2rem;
  // }

  .topBlock__logo {
    align-items: center;
    width: 10.6rem;
    margin-left: 0.9rem;

    img {
      width: 100%;
    }
  }

  .topBlock__nav {
    padding: 1rem 0 0 0.9rem;
    gap: 1rem;

    &__group {
      gap: 1.43rem;

      &:nth-child(1) {
        .topBlock__nav__group__title {
          display: none;
        }
      }

      &:nth-child(2) {
        border: none;
      }

      &:nth-child(3) {
        border: none;
      }

      &__title {
        display: block;
      }

      ul {
        align-items: flex-start;
        gap: 1.86rem;

        li {
          a,
          .topBlock__nav_ul_li_a {
            span {
              display: inline;
              animation: appearence 0.5s linear;
            }
          }

          a.active {
            &::before {
              content: '';
              position: absolute;
              bottom: 0;
              left: -2.2rem;
              width: 0.5rem;
              height: 1.8rem;
              border-radius: 0px 3px 3px 0px;
              background-color: var(--primary-color);
            }
          }
        }
      }
    }
  }

  .bottomBlock__refer {
    display: flex;
  }

  .bottomBlock__user__info {
    &__imgBlock {
      width: 2.85rem;
    }

    &__card {
      display: flex;
      animation: appearence 0.3s linear;
    }
  }
}

@keyframes appearence {
  0% {
    opacity: 0;
    display: none;
  }
  18% {
    opacity: 0;
    display: none;
  }
  100% {
    opacity: 1;
  }
}

/*MAIN*/

.main {
  @include standartBlock();
  flex: 1 1 auto;
}

.mainAuth {
  @include standartBlock($justify: center);
  // padding: 2.29rem 2.29rem 2.29rem calc(4% + 2.29rem);
  // flex: 1 1 auto;
}
.invite-x-icon {
  position: absolute;
  top: 24px;
  right: 24px;
  border-radius: 4px;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s all;
  &:hover {
    background: #f3f3f3;
    border-radius: 4px;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.modal-footer {
  gap: 16px;
}
//.addProjectWrapper{
//  .css-13cymwt-control{
//    height: 48px;
//    .css-hlgwow{
//      padding: 0;
//      .css-1dimb5e-singleValue{
//        display: flex;
//        align-items: center;
//        padding: 0 14px;
//
//
//      }
//    }
//  }
//
//}
