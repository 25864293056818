@use '../../styles' as *;

.profile {
  gap: 3rem;
  @media (max-width: 991px) {
    margin-bottom: 60px;
  }

  &__table {
    @include standartBlock($align: flex-start);
    gap: 3rem;

    &__top {
      @include standartBlock($direction: row, $justify: flex-start);
      overflow-x: auto;
      overflow-y: hidden;
      width: 100%;

      gap: 1rem;
      border-bottom: 0.3px solid $headerFont;
      z-index: 5;
      width: fit-content;

      &__cell {
        @include standartBlock($direction: row, $width: auto);
        padding: 0 2.5rem 0.8rem 2.5rem;
        font-size: 1.15rem;
        font-weight: 400;
        color: $headerFont;
        text-transform: capitalize;
        gap: 1rem;
        cursor: pointer;
        z-index: 10;
        white-space: nowrap;
        white-space: nowrap;
      }

      &__cellActive {
        color: var(--primary-color);

        &::before {
          content: '';
          position: absolute;
          width: 100%;
          bottom: -2%;
          left: 0;
          background-color: var(--primary-color);
          height: 2px;
          z-index: 10;
        }
      }
    }

    &__body {
      @include standartBlock();
    }
  }
}
