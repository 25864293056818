.title {
  font-size: 20px;
  font-weight: 500;
}

.valueWrapper {
  background-color: #f4f4f5;
  border-radius: 4px;
  padding: 0.75rem;
}

.imageProperties {
  border: 1px solid rgba($color: #282828, $alpha: 0.2);
  max-width: 380px;
}

.image {
  width: fit-content;
  height: fit-content;
  max-width: 100%;
}
