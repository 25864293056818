@use '../../../styles' as *;

.container {
  @include standartBlock();
  border-radius: 1.15rem;
  overflow: hidden;

  &__hiddenScreen {
    @include standartBlock();

    &__btns {
      @include standartBlock($direction: row);
      position: absolute;
      padding: 2rem;
      z-index: 10;

      &__group {
        position: relative;
        display: flex;
        gap: 1rem;
      }

      button {
        @include standartBlock($width: auto);
        padding: 0.7rem;
        border-radius: 50%;
        border: none;
        background: rgba($color: #000000, $alpha: 0.4);

        .svg {
          width: 2rem;
          height: 2rem;
        }
      }
    }

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  &__images {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, minmax(50px, 1fr));
    grid-auto-flow: dense;
    gap: 2rem;

    &__imgBlock {
      position: relative;
      display: flex;
      cursor: pointer;

      img {
        width: 100%;
        object-fit: cover;
        z-index: 10;
      }

      &__loaderBlock {
        @include standartBlock($justify: center);
        position: absolute;
        height: 100%;
        z-index: 15;
      }
    }
  }
}
