@use '../../styles' as *;

.graphics {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
  &__progress {
    flex-grow: 0.8;
    @media screen and (max-width: 1030px) {
      flex-grow: 1;
    }
    height: auto;
    padding: 2.45rem 2.3rem;
    border-radius: 2rem;
    border: 1px solid rgba(40, 40, 40, 0.15);
    background: $boldHeaderFont;
    &__title {
      font-size: 1.73rem;
      font-weight: 500;
      color: white;
      margin-bottom: 1.45rem;
    }

    &__bar {
      position: relative;
      width: 100%;
      height: 0.9rem;
      border-radius: 6px;
      overflow: hidden;
      background: $checkedBackground;
      z-index: 5;
      margin-bottom: 1.45rem;

      &__line {
        height: 0.9rem;
        border-radius: 6px;
        background: $progressBar;
        transition: width 0.3s linear;
      }
    }

    &__btn {
      @include standartBlock($width: auto);
      padding: 0.9rem 1.7rem;
      background: white;
      border-radius: 40px;
      font-family: 'Roboto';
      font-size: 1.15rem;
      font-weight: 500;
      color: $boldHeaderFont;
      border: none;
    }
  }

  &__usage {
    @include standartBlock($width: 35%, $align: flex-start);
    @media screen and (max-width: 1280px) {
      height: 130px;
    }
    @media screen and (max-width: 1030px) {
      width: 60%;
      height: 180px;
    }
    @media screen and (max-width: 540px) {
      width: 100% !important;
    }
    padding-right: 1.25rem;
    padding-top: 1.25rem;
    flex-wrap: wrap;
    border-radius: 2rem;
    border: 1px solid $formBorder;
    // background: #f3f3f3;
    overflow: hidden;

    &__title {
      width: 100%;
      font-size: 1.45rem;
      font-weight: 500;
      color: $boldHeaderFont;
      text-align: left;
    }

    &__chart {
      @include standartBlock($justify: auto, $align: auto);
      position: absolute;
      bottom: 0;
      width: 90%;
      // height: 13rem;
    }
  }

  &__wordsUsage {
    width: 200px;
    @media screen and (max-width: 540px) {
      min-width: 100%;
    }
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    @media screen and (max-width: 1280px) {
      width: 190px;
    }
    @media screen and (min-width: 2380px) {
      width: 240px;
    }
    border-radius: 2rem;
    // padding-top: 1.45rem;
    background: $progressBar;
    &__title {
      width: 100%;
      font-size: 1.45rem;
      font-weight: 500;
      color: $boldHeaderFont;
      text-align: left;
    }

    &__prop {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%);
      font-size: 1.8rem;
      font-weight: 500;
      color: $boldHeaderFont;
    }

    &__chart {
      position: absolute;
      top: 2.4rem;
      width: 16rem;
    }
  }
}
