:root {
  // Master Colors
  --background-color: #fff; /* Default value app container background */

  --header-background: #f3f3f3; /* Default value sidebar */
  --secondary-font-color: #616161; /* Default value */
  --bold-header-font: #282828;
  --progress-bar: #c6ff00;

  // Workflow Colors
  --marketing-workflow-btn-color: #9dc8ee;
  --sales-workflow-btn-color: #8fd7bd;
  --hr-workflow-btn-color: #e4ed96;
  --finance-workflow-btn-color: #ffaf95;


  // Why it was removed ?
  --active-nav: #2468ff;

  // Primary Colors
  --primary-color: #2468ff;

  // Primary Font Color
  --primary-font-color: #282828;
  --secondary-font-color: #616161;

  // Reshape
  --primary-btn-color: #8d9ec4;
  --secondary-btn-color: #282828;
}

// NAVBAR
$headerBackground: var(--header-background);
$headerFont: var(--secondary-font-color);
$boldHeaderFont: var(--primary-font-color);
$activeNav: var(--active-nav);
$progressBar: var(--progress-bar);

// MODAL FORM
$modalBackground: #ffff;
$checkedBackground: #f3f3f3b5;

// WORKFLOWS
$cellBorder: rgba(40, 40, 40, 0.1);

// MARKETING
$marketingIconsBckgr: var(--marketing-workflow-btn-color);

// SALES
$salesIconsBckgr: var(--sales-workflow-btn-color);

// HR
$hrIconsBckgr: var(--hr-workflow-btn-color);

// FINANCE
$financeIconsBckgr: var(--finance-workflow-btn-color);

// FORM
$formBorder: rgba(40, 40, 40, 0.2);
$paleFont: #a1a5b7;
$paleBackground: #f1faff;

// TABLE
$tableHeaderBackground: #ebecf2;
$tableRowBackground: rgba(40, 40, 40, 0.03);
$completeBackground: rgba(16, 172, 132, 0.2);
$completeText: #10ac84;

// PLAN
$listColor: #3f3f42;
