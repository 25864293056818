@use '../../styles' as *;

.auth {
  @include standartBlock($width: 40%, $justify: flex-start);
  padding: 3rem 8rem;
  @media screen and (max-width: 1200px) {
    width: 50%;
    padding: 3rem 7rem;
  }
  @media screen and (max-width: 1024px) {
    width: 70%;
    padding: 3rem 6rem;
  }
  @media screen and (max-width: 768px) {
    width: 95%;
    padding: 3rem 5rem;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
    padding: 3rem 2rem;
  }

  border-radius: 0.5rem;
  z-index: 101;
  background: $modalBackground;
  gap: 2.5rem;

  &__topBlock {
    @include standartBlock();
    gap: 1.5rem;

    &__title {
      font-size: 2rem;
      font-weight: 500;
      color: $boldHeaderFont;
    }

    &__subTitle {
      font-size: 1.29rem;
      font-weight: 500;
      color: $paleFont;
    }

    &__googleBtn {
      @include standartBlock($direction: row, $justify: center);
      padding: 1rem;
      border-radius: 0.5rem;
      font-size: 1.29rem;
      font-weight: 500;
      color: var(--primary-color);
      gap: 1rem;
      background: $modalBackground;
      border: 1px solid $formBorder;
      transition: all 0.2s linear;

      &:hover {
        color: $modalBackground;
        background: var(--primary-color);
      }

      svg {
        width: 1.7rem;
        height: 1.7rem;
      }
    }

    &__separator {
      @include standartBlock();
      font-size: 1.29rem;
      font-weight: 500;
      color: $paleFont;
      text-transform: uppercase;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        background: $paleFont;
        width: 100%;
        height: 0.5px;
        z-index: 5;
      }

      span {
        padding: 0 1rem;
        background: $modalBackground;
        z-index: 10;
      }
    }
  }

  .messageError {
    display: block;
    color: red;
  }

  .messageSuccess {
    display: block;
    color: green;
  }

  &__form {
    @include standartBlock();
    gap: 2rem;

    &__label {
      @include standartBlock($justify: flex-start, $align: flex-start);
      gap: 1rem;

      &__title {
        font-size: 1.29rem;
        font-weight: 500;
        color: $boldHeaderFont;
      }

      input {
        @include standartBlock();
        padding: 1rem;
        border-radius: 0.5rem;
        border: 1px solid $formBorder;
        font-size: 1.15rem;
        font-weight: 400;
        color: $boldHeaderFont;

        &:focus-visible {
          border: 1px solid $modalBackground;
          outline: 2px solid var(--primary-color);
        }
      }
    }

    &__labelCheckbox {
      flex-direction: row-reverse;
      align-items: center;
      justify-content: flex-end;

      .auth__form__label__title {
        font-size: 1.15rem;
        font-weight: 400;
      }

      input {
        width: auto;
      }

      .messageError {
        position: absolute;
        right: 0;
      }
    }

    &__linkBlock {
      @include standartBlock($direction: row, $justify: flex-end);
      font-size: 1.15rem;
      font-weight: 400;
    }

    a {
      color: var(--primary-color);
    }
    button {
      padding: 1.2rem;
      border-radius: 0.5rem;
    }
  }
  &__footer {
    @include standartBlock($direction: column, $justify: center);
    // margin-top: 1rem;
    font-size: 1.1rem;
    font-weight: 400;
    color: $paleFont;
    gap: 1rem;

    &__email {
      color: var(--primary-color);
    }
  }

  &__signUp {
    @include standartBlock($direction: row, $justify: center);
    // margin-top: 1rem;
    font-size: 1.29rem;
    font-weight: 500;
    color: $paleFont;
    gap: 1rem;

    a {
      color: var(--primary-color);
    }
  }
}
